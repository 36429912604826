import http from './request'

const api = {
    index: {
        dashboard: {
            statistics: async function (params = {}) {
                return await http.getAsync('/agent/dashboard/statistics', params)
            }
        }
    },
    login: async function (params = {}) {
        return await http.postAsync('/agent/login', params)
    },
    uploadImage: function (params = {}, header) {
        return http.post('/agent/merchants/upload', params, header)
    },
    uploadImageOnlyUrl: function (params = {}, header) {
        return http.post('/agent/upload', params, header)
    },
    ocrDiscern: function (params = {}, header) {
        return http.post('/agent/upload/ocr-discern', params, header)
    },
    banks: {
        branchBanks: function (params = {}) {
            return http.get('/agent/branch_banks', params)
        },
        list: function (params = {}) {
            return http.get('/agent/banks', params)
        }
    },
    merchant: {
        list: async function (params = {}) {
            return await http.getAsync('/agent/merchants', params)
        },
        create: async function (params = {}) {
            return await http.postAsync('/agent/merchants', params)
        },
        edit: async function (id, params = {}) {
            return await http.postAsync('/agent/merchants/' + id, params)
        },
        select: async function (id, params = {}) {
            return await http.getAsync('/agent/merchants/' + id, params)
        },
        updateSmsConfig: function (id, params = {}) {
            return http.post(`/agent/merchants/${id}/sms_config`, params)
        },
        income: {
            applyProto: async function (params = {}) {
                return await http.postAsync('/agent/merchants/apply_agreement', params)
            },
            selectEcNo: async function (params = {}) {
                return await http.getAsync('/agent/merchants/get_ec_no', params)
            },
            confirmMaterial: async function (params = {}) {
                return await http.postAsync('/agent/merchants/confirmMaterial', params)
            },
            auth: async function (params = {}) {
                return await http.getAsync('/agent/merchants/auth', params)
            },

            /** 进件 */
            incoming: async function (params = {}) {
                return await http.postAsync('/agent/merchants/incoming', params)
            },
            /**重新提交 */
            againIncoming: async function (params = {}) {
                return await http.postAsync('/agent/merchants/again-incoming', params)
            },
            /**费率修改 */
            changeMerchantRate: async function (params = {}) {
                return await http.postAsync('/agent/merchants/change-merchant-rate', params)
            },
            /**修改商户通道资料 */
            changeMerchantInfo: async function (params = {}) {
                return await http.postAsync('/agent/merchants/change-merchant-info', params)
            },
            /**修改商户结算信息 */
            changeMerchantSettlement: async function (params = {}) {
                return await http.postAsync('/agent/merchants/change-merchant-settlement', params)
            },
            /**获取一级行业码 */
            getGtxyTopMcc: async function (params = {}) {
                return await http.getAsync('/agent/merchants/gtxy_top_mcc', params)
            },
            /**获取二级行业码 */
            getGtxySecondMcc: async function (params = {}) {
                return await http.getAsync('/agent/merchants/gtxy_second_mcc', params)
            },
            /**获取银行代码 */
            getGtxyBankinfo: async function (params = {}) {
                return await http.getAsync('/agent/merchants/gtxy_bankinfo', params)
            }
        },
        merchantChange: async function (params = {}) {
            return await http.postAsync('/agent/merchants/change', params)
        },
        channel: {
            channel: async function (id, params = {}) {
                return await http.getAsync('/agent/merchants/' + id + '/payment_channels?id=' + id, params)
            },
            payConfig: async function (params = {}) {
                return await http.getAsync('/agent/merchants/pay_config', params)
            },
            deletePaymentChannels: async function (id, params = {}) {
                return await http.postAsync('/agent/merchants/' + id + '/delete_payment_channels', params)
            },
            savePaymentChannels: async function (id, params = {}) {
                return await http.postAsync('/agent/merchants/' + id + '/add_payment_channels', params)
            },
            editPaymentChannels: async function (id, params = {}) {
                return await http.postAsync('/agent/merchants/' + id + '/edit_payment_channels', params)
            },
            changePaymentChannelAutoOpen: async function (params = {}) {
                return await http.postAsync('/agent/merchants/edit_payment_channels/auto_open', params)
            },
            // postpay请求应用授权
            postpayRequestAuth: function (params = {}) {
                return http.post('agent/merchants/postpay_request_auth', params)
            },
            // postpay查询应用授权
            postpayQueryAuth: function (params = {}) {
                return http.post('agent/merchants/postpay_query_auth', params)
            },
            // gtxyAnxinQianRequest
            gtxyAnxinQianRequest: function (params = {}) {
                return http.post('agent/merchants/gtxy_anxin_qian_request', params)
            },
            // gtxyAnxinQianVerify
            gtxyAnxinQianVerify: function (params = {}) {
                return http.post('agent/merchants/gtxy_anxin_qian_verify', params)
            },
        },
        store: {
            list: async function (params = {}) {
                return await http.getAsync('/agent/stores', params)
            },
            add: async function (params = {}) {
                return await http.postAsync('/agent/stores', params)
            },
            edit: async function (id, params = {}) {
                return await http.putAsync('/agent/stores/' + id, params)
            },
            cashRegisterList: async function (params = {}) {
                return await http.getAsync('/agent/codelist', params)
            },
            cashierCodeList: async function (params = {}) {
                return await http.getAsync('/agent/cashiers', params)
            },
            addCashierCode: async function (params = {}) {
                return await http.postAsync('/agent/addcode', params)
            }
        },
        cashier: {
            list: async function (params = {}) {
                return await http.getAsync('/agent/cashiers', params)
            },
            add: async function (params = {}) {
                return await http.postAsync('/agent/cashiers', params)
            },
            edit: async function (id, params = {}) {
                return await http.putAsync('/agent/cashiers/' + id, params)
            },
            delete: async function (id, params = {}) {
                return await http.deleteAsync('/agent/cashiers/' + id, params)
            }
        },
        device: {
            list: async function (url, params = {}) {
                return await http.getAsync(url, params)
            },
            deviceCategory: async function (params = {}) {
                return await http.getAsync('/agent/device_categories', params)
            },
            bindStore: async function (id, params) {
                return await http.postAsync('/agent/devices/' + id + '/bind_store', params)
            },
            unBindStore: async function (id, params = {}) {
                return await http.postAsync('agent/merchants/' + id + '/un_bind_devices', params)
            },
            unBindDeviceList: async function (params = {}) {
                return await http.getAsync('/agent/devices', params)
            },
            bindDevices: async function (id, params) {
                return await http.postAsync('/agent/merchants/' + id + '/bind_devices', params)
            }
        },
        activity: {
            list: async function (params = {}) {
                return await http.getAsync('/agent/merchant/preferential/activity-list', params)
            },
            register: async function (merchantId, params = {}) {
                return await http.postAsync('/agent/merchant/preferential/' + merchantId + '/activity-register', params)
            },
            requestedRegister: async function (merchantId, params = {}) {
                return await http.getAsync('/agent/merchant/preferential/' + merchantId + '/requested-activity-list', params)
            },
            requestedActivityStatus: async function (merchantId, params = {}) {
                return await http.getAsync('/agent/merchant/preferential/' + merchantId + '/status-select', params)
            },
            setActivityRates: async function (merchantId, params = {}) {
                return await http.postAsync('/agent/merchant/preferential/' + merchantId + '/rate-update', params)
            },
            /**国通活动列表 */
            gtxyActList: async function (params = {}) {
                return await http.getAsync('/agent/merchants/gtxy_bank_act/get_act_list/', params)
            },
            /**活动报名 */
            activitySubmit: async function (params = {}) {
                return await http.postAsync('/agent/merchants/gtxy_bank_act/submit/', params)
            },
            /**商家补贴详情列表 */
            merchantSubList: async function (params = {}) {
                return await http.getAsync('/agent/merchants/gtxy_bank_act/get_merchant_sub_list/', params)
            },
            /**商家已报名活动列表 */
            merchantSignedupList: async function (params = {}) {
                return await http.getAsync('/agent/merchants/gtxy_bank_act/get_merchant_signed_up_list/', params)
            },
            /**商户补充材料图片上传 */
            uploadImg: async function (params = {}) {
                return await http.postAsync('/agent/merchants/gtxy_bank_act/upload_img/', params)
            },

        }
    },
    salesman: {
        list: async function (params = {}) {
            return await http.getAsync('/agent/salesmen', params)
        },
        add: async function (params = {}) {
            return await http.postAsync('/agent/salesmen', params)
        },
        edit: async function (id, params = {}) {
            return await http.putAsync('/agent/salesmen/' + id, params)
        },
        statistical: async function (params = {}) {
            return await http.getAsync('/agent/salesman/statistical', params)
        },
        export: function (params = {}, filename) {
            return http.download('/agent/salesman/statistics_export', params, filename)
        },
        levelAgentSalesman: async function (params = {}) {
            return await http.getAsync('/agent/salesman/list', params)
        },
    },
    agent: {
        list: async function (params = {}) {
            return await http.getAsync('/agent/agents', params)
        },
        getOne: async function (id, params = {}) {
            return await http.getAsync('/agent/agents/' + id, params)
        },
        addAgent: async function (params = {}) {
            return await http.postAsync('/agent/agents', params)
        },
        editAgent: async function (id, params = {}) {
            return await http.putAsync('/agent/agents/' + id, params)
        },
        delete: async function (id, params = {}) {
            return await http.deleteAsync('/agent/agents/' + id, params)
        },
        device: {
            list: async function (id, params = {}) {
                return await http.getAsync('/agent/agents/' + id + '/devices', params)
            },
            unBindDeviceList: async function (params = {}) {
                return await http.getAsync('/agent/devices', params)
            },
            bindDevices: async function (id, params) {
                return await http.postAsync('/agent/agents/' + id + '/bind_devices', params)
            },
            unBindDevices: async function (id, params) {
                return await http.postAsync('/agent/agents/' + id + '/un_bind_devices', params)
            },
        },
        rate: {
            saveRate: async function (id, params) {
                return await http.postAsync('/agent/agents/' + id + '/rate', params)
            },
        },
        profit: {
            list: async function (params = {}) {
                return await http.getAsync('/agent/agents/profit/statistics', params)
            },
            subordinateAgentsData: async function (params = {}) {
                return await http.getAsync('/agent/agents/profit/subordinate_agents_data', params)
            },
            export: function (params = {}, filename) {
                return http.download('/agent/agents/profit/statistics_export', params, filename)
            },
        },
        levelAgents: {
            list: async function (params = {}) {
                return await http.getAsync('/agent/agents/level-agents', params)
            },
        },
        //代理商名称
        agentList: async function (params = {}) {
            return await http.postAsync('/agent/agents/agent-list', params)
        },
        apiWalletLogs: function (params = {}) {
            return http.get('/agent/agent_api_wallet/logs', params)
        },
        apiWalletDetail: function (params = {}) {
            return http.get('/agent/agent_api_wallet/detail', params)
        },
        commission: {
            //佣金申请列表
            commissionList: async function (params = {}) {
                return await http.getAsync('/agent/agent_commission/index', params)
            },
            //佣金确认
            commissionConfirm: async function (params = {}) {
                return await http.postAsync('/agent/agent_commission/confirm', params)
            },
            //上传文件
            uploadFile: async function (params = {}) {
                return await http.postAsync('/agent/agent_commission/upload', params)
            },
            //提交佣金结算申请
            applyCommission: async function (params = {}) {
                return await http.postAsync('/agent/agent_commission/add_apply_record', params)
            },
            //佣金申请记录
            commissionAuditList: async function (params = {}) {
                return await http.getAsync('/agent/agent_commission/list_apply_record', params)
            },
            //修改佣金申请
            applyCommissionEdit: async function (params = {}) {
                return await http.postAsync('/agent/agent_commission/edit_apply_record', params)
            },
            //待审核
            todoList: async function (params = {}) {
                return http.getAsync("/agent/agent_commission/todo_count", params)
            }
        }
    },
    channel: {
        paymentChannels: async function (params = {}) {
            return await http.getAsync('/agent/payment_channels', params)
        }
    },
    mcc: {
        list: function (params = {}) {
            return http.get('/agent/mccs', params)
        }
    },
    order: {
        list: async function (params = {}) {
            return await http.getAsync('/agent/orders', params)
        },
        details: async function (params = {}) {
            return await http.getAsync('/agent/orders/detail', params)
        },
        syncStatus: async function (params = {}) {
            return await http.getAsync('/agent/orders/sync_status', params)
        },
        refund: {
            list: async function (params = {}) {
                return await http.getAsync('/agent/orders/refund/list', params)
            },
            details: async function (params = {}) {
                return await http.getAsync('/agent/orders/refund/details', params)
            },
        },
        statistics: {
            merchantStatisticsCollect: async function (params = {}) {
                return await http.getAsync('/agent/orders/merchant-statistics-collect', params)
            },
            merchantStatistics: async function (params = {}) {
                return await http.getAsync('/agent/orders/merchant-statistics', params)
            },
            export: function (params = {}, filename) {
                return http.download('/agent/orders/merchant-statistics-export', params, filename)
            },
            merchantOrderDetailCollect: function (params = {}) {
                return http.getAsync('/agent/orders-collect', params)
            },
            merchantOrderDetailCollectNew: function (params = {}) {
                return http.getAsync('/agent/orders-collect-new', params)
            },
            merchantList: function (params = {}) {
                return http.getAsync('/agent/orders/statistic/list', params)
            },
            merchantListCollect: function (params = {}) {
                return http.getAsync('/agent/orders/statistic/collect', params)
            },
            paymentChannels: function (params = {}) {
                return http.getAsync('/agent/payment_channels', params)
            },
        },
        //导出excle
        statisticsExport: function (params = {}, filename) {
            return http.download('/agent/orders/order-transaction-export', params, filename)
        },

    },
    device: {
        list: async function (params = {}) {
            return await http.getAsync('/agent/devices', params)
        },
    },
    invoice: {
        record: async function (params = {}) {
            return await http.getAsync('/agent/invoice/billing_record', params)
        },
        apply: async function (params = {}) {
            return await http.postAsync('/agent/invoice/apply_invoicing', params)
        },
    },
    ad: {
        add: async function (params = {}) {
            return await http.postAsync('/agent/advertisement', params)
        },
        edit: async function (params = {}) {
            return await http.postAsync('/agent/advertisement/update', params)
        },
        del: async function (params = {}) {
            return await http.postAsync('/agent/advertisement/delete', params)
        },
        list: async function (params = {}) {
            return await http.getAsync('/agent/advertisement/list', params)
        },
    },
    settlement: {
        record: async function (params = {}) {
            return await http.getAsync('/agent/settlement_record', params)
        },
        agent: function (params = {}) {
            return http.getAsync('/agent/settlement', params)
        },
        salesman: function (params = {}) {
            return http.getAsync('/agent/salesman', params)
        },
    },
    //终端
    terminals: {
        //获取终端列表
        getTerminalList: function (params = {}) {
            return http.get('/agent/terminals', params)
        },
        //新增终端
        addNewTerminal: async function (params = {}) {
            return await http.post('/agent/terminals', params)
        },

        //更新终端信息
        updateTerminal: async function (id, params = {}) {
            return await http.put('/agent/terminals/' + id, params)
        },
        //获取终端详情
        getTerminalDetails: async function (id) {
            return await http.get(`/agent/terminals/${id}`)
        },
        testSpeaker: async function (params = {}) {
            return await http.post('/agent/terminals/test_speaker', params)
        },
    },
    dict: {
        getDeviceDict: async function (params = {}) {
            return await http.get('/agent/dict/device', params)
        },
        getMerchantDict: async function (params = {}) {
            return await http.get('/agent/dict/merchant', params)
        },
        getAgentDict: async function (params = {}) {
            return await http.get('/agent/dict/agent', params)
        },
        getStoreDict: async function (params = {}) {
            return await http.get('/agent/dict/store', params)
        },
    },
    reSetPassword: {
        reSetAgent: async function (params = {}) {
            return await http.post('/agent/rest_password', params)
        }
    },
    agents: {
        // 查询设备分类
        queryDeviceCategories: function () {
            return http.get("/agent/device_categories")
        },
    },
    thirdPartyDevice: {
        congMingPayCategory: async function () {
            return await http.get("/agent/third_party_device/congmingpay/category")
        },
        congMingPayPluginChildren: async function (params) {
            return await http.get("/agent/third_party_device/congmingpay/plugin_children", params)
        },
    },
}

export default api
