<template>
    <el-container class="container">
        <el-header>
            <el-steps :active="step" align-center>
                <el-step title="资质信息" />
                <el-step title="基础信息" />
                <el-step title="结算信息" />
            </el-steps>
        </el-header>
        <el-main class="main" v-loading="isLoad">
            <el-form ref="form" :model="form" label-width="150px" label-position="right" :scroll-to-error="true"
                require-asterisk-position="left" :rules="rules" :inline-message="true" size="large">
                <div id="step-one" class="form-box" v-if="step == 1">
                    <el-row>
                        <el-col :span="12">
                            <el-form-item label="业务员：" prop="salesman_id">
                                <el-select v-model="form.salesman_id" placeholder="选择业务员" :disabled="isIncome"
                                    clearable>
                                    <el-option v-for="item in salesmenOptions" :key="item.name"
                                        :label="item.name + '-' + item.phone" :value="item.id" />
                                </el-select>
                            </el-form-item>
                        </el-col>
                    </el-row>
                    <el-row>
                        <el-col :span="12">
                            <el-form-item label="商户类型：">
                                <el-radio-group v-model="form.business_type" :disabled="isIncome">
                                    <el-radio-button :label="0">
                                        企业商户
                                    </el-radio-button>
                                    <el-radio-button :label="1">
                                        个体工商
                                    </el-radio-button>
                                    <el-radio-button :label="2">
                                        小微商户
                                    </el-radio-button>
                                </el-radio-group>
                            </el-form-item>
                        </el-col>
                    </el-row>
                    <el-row v-if="form.business_type == 2">
                        <el-col :span="12">
                            <el-form-item label="商户名：" prop="merchant_name">
                                <el-input v-model="form.merchant_name" clearable :disabled="isIncome"
                                    placeholder="商户_XXX 例:商户_张三" />
                            </el-form-item>
                        </el-col>
                        <el-col :span="12">
                            <el-form-item label="商户简称：" prop="merchant_alias">
                                <el-input v-model="form.merchant_alias" clearable :disabled="isIncome"
                                    placeholder="商户简称为商户的品牌名称,例:KFC肯德基" />
                            </el-form-item>
                        </el-col>
                    </el-row>
                    <template v-if="form.business_type == 1 || form.business_type == 0">
                        <el-row>
                            <el-col :span="12">
                                <el-form-item label="营业执照：" prop="license_pic">
                                    <c-upload ref="licenseRef" @success="uploadBusinessLicense" :img="license_pic"
                                        text="营业执照" :isDisabled="isIncome" />
                                    <el-input style="display:none" v-model="form.license_pic" />
                                </el-form-item>
                            </el-col>
                        </el-row>
                        <el-row>
                            <el-col :span="12">
                                <el-form-item label="公司类型：" prop="company_type">
                                    <el-select v-model="form.company_type" placeholder="选择公司类型" clearable
                                        :disabled="isIncome">
                                        <el-option v-for="item in companyTypes" :key="item" :label="item.type"
                                            :value="item.id" />
                                    </el-select>
                                </el-form-item>
                            </el-col>
                            <el-col :span="10">
                                <el-form-item label="执照有效期：" prop="licenseDate">
                                    <el-date-picker v-model="form.licenseDate" type="daterange" range-separator="至"
                                        start-placeholder="开始时间" end-placeholder="结束时间" :disabled="isIncome" />
                                </el-form-item>
                            </el-col>
                            <el-col :span="2">
                                <el-form-item label-width="20px">
                                    <el-checkbox v-model="form.license_expiry_forever" label="长期"
                                        :disabled="isIncome" />
                                </el-form-item>
                            </el-col>
                        </el-row>
                        <el-row>
                            <el-col :span="12">
                                <el-form-item label="营业执照名称：" prop="merchant_company">
                                    <el-input v-model="form.merchant_company" clearable :disabled="isIncome"
                                        placeholder="上传营业执照时自动填写,请校对" />
                                </el-form-item>
                            </el-col>
                            <el-col :span="12">
                                <el-form-item label="统一信用代码：" prop="license_no">
                                    <el-input v-model="form.license_no" maxlength="18" clearable :disabled="isIncome"
                                        placeholder="上传营业执照时自动填写,请校对" />
                                </el-form-item>
                            </el-col>
                        </el-row>
                        <el-row>
                            <el-col :span="12">
                                <el-form-item label="商户名：" prop="merchant_name">
                                    <el-input v-model="form.merchant_name" clearable :disabled="isIncome"
                                        placeholder="上传营业执照时自动填写,请校对" />
                                </el-form-item>
                            </el-col>
                            <el-col :span="12">
                                <el-form-item label="商户简称：" prop="merchant_alias">
                                    <el-input v-model="form.merchant_alias" clearable :disabled="isIncome"
                                        placeholder="商户简称为商户的品牌名称,例:KFC肯德基" />
                                </el-form-item>
                            </el-col>
                        </el-row>
                    </template>
                    <template v-if="form.business_type == 2">
                        <el-row>
                            <el-col :span="8">
                                <el-form-item label="手持身份证：" prop="legal_person_holding_id_card">
                                    <c-upload ref="holdingIdCardRef" @success="uploadHoldingIdCard"
                                        :img="legal_person_holding_id_card" text="手持身份证" />
                                    <el-input style="display:none" v-model="form.legal_person_holding_id_card" />
                                </el-form-item>
                            </el-col>
                            <el-col :span="8">
                                <el-form-item label="法人身份证正面：" prop="legal_person_id_card_front">
                                    <c-upload ref="uploadIdCardFrontRef" @success="uploadIdCardFront"
                                        :img="legal_person_id_card_front" text="身份证正面" :isDisabled="isIncome" />
                                    <el-input style="display:none" v-model="form.legal_person_id_card_front" />
                                </el-form-item>
                            </el-col>
                            <el-col :span="8">
                                <el-form-item label="法人身份证反面：" prop="legal_person_id_card_reverse">
                                    <c-upload ref="uploadIdCardReverseRef" @success="uploadIdCardReverse"
                                        :img="legal_person_id_card_reverse" text="身份证反面" :isDisabled="isIncome" />
                                    <el-input style="display:none" v-model="form.legal_person_id_card_reverse" />
                                </el-form-item>
                            </el-col>
                        </el-row>
                    </template>

                    <el-row v-if="form.business_type != 2">
                        <el-col :span="12">
                            <el-form-item label="法人身份证正面：" prop="legal_person_id_card_front">
                                <c-upload ref="uploadIdCardFrontRef" @success="uploadIdCardFront"
                                    :img="legal_person_id_card_front" text="身份证正面" :isDisabled="isIncome" />
                                <el-input style="display:none" v-model="form.legal_person_id_card_front" />
                            </el-form-item>
                        </el-col>
                        <el-col :span="12">
                            <el-form-item label="法人身份证反面：" prop="legal_person_id_card_reverse">
                                <c-upload ref="uploadIdCardReverseRef" @success="uploadIdCardReverse"
                                    :img="legal_person_id_card_reverse" text="身份证反面" :isDisabled="isIncome" />
                                <el-input style="display:none" v-model="form.legal_person_id_card_reverse" />
                            </el-form-item>
                        </el-col>
                    </el-row>
                    <el-row>
                        <el-col :span="12">
                            <el-form-item label="法人姓名：" prop="legal_person_name">
                                <el-input v-model="form.legal_person_name" maxlength="5" clearable
                                    :disabled="isIncome" />
                            </el-form-item>
                        </el-col>
                        <el-col :span="12">
                            <el-form-item label="法人证件号：" prop="legal_person_id_card_no">
                                <el-input v-model="form.legal_person_id_card_no" maxlength="18" clearable
                                    :disabled="isIncome" />
                            </el-form-item>
                        </el-col>
                    </el-row>
                    <el-row>
                        <el-col :span="12">
                            <el-form-item label="法人证件有效期：" prop="legalPersonIdCardDate">
                                <el-date-picker v-model="form.legalPersonIdCardDate" type="daterange"
                                    range-separator="至" start-placeholder="开始时间" end-placeholder="结束时间"
                                    :disabled="isIncome" />
                            </el-form-item>
                        </el-col>
                        <el-col :span="2">
                            <el-form-item label-width="30px">
                                <el-checkbox v-model="form.legal_person_id_card_expiry_forever" label="长期" size="large"
                                    :disabled="isIncome" />
                            </el-form-item>
                        </el-col>
                    </el-row>
                </div>
                <div id="step-two" class="form-box" v-if="step == 2">
                    <el-row>
                        <el-col :span="12">
                            <el-form-item label="商户类别(MCC):" prop="mcc_codes">
                                <el-cascader :options="mccOptions" v-model="form.mcc_codes" :props="mccProps" clearable
                                    :disabled="isIncome" />
                            </el-form-item>
                        </el-col>
                    </el-row>

                    <el-row>
                        <el-col :span="12">
                            <el-form-item label="经营地：" prop="business_city" style="width: 100%;">
                                <c-cascader ref="businessCityRef" @success="businessCity"
                                    :defaultOption="form.business_city" :disabled="isIncome"
                                    style="width: 100%;"></c-cascader>
                                <el-input v-model="form.business_city" style="display:none" :disabled="isIncome" />
                            </el-form-item>
                        </el-col>
                        <el-col :span="12">
                            <el-form-item label="经营地址：" prop="business_address">
                                <el-input v-model="form.business_address" clearable :disabled="isIncome" />
                            </el-form-item>
                        </el-col>
                    </el-row>
                    <!-- <el-row>
                        <el-col :span="12">
                            <el-form-item label="经营范围：" prop="business_scope">
                                <el-input v-model="form.business_scope" clearable :disabled="isIncome" />
                            </el-form-item>
                        </el-col>
                    </el-row> -->
                    <el-row>
                        <el-col :span="12">
                            <el-form-item label="联系人：" prop="contact_person_name">
                                <el-input v-model="form.contact_person_name" clearable :disabled="isIncome" />
                            </el-form-item>
                        </el-col>
                        <el-col :span="12">
                            <el-form-item label="联系人手机号：" prop="contact_person_phone">
                                <el-input v-model="form.contact_person_phone" maxlength="11" clearable
                                    :disabled="isIncome" />
                            </el-form-item>
                        </el-col>
                    </el-row>
                    <el-row>
                        <el-col :span="8">
                            <el-form-item label="门头照片：" prop="store_header">
                                <c-upload ref="uploadStoreHeaderRef" @success="uploadStoreHeader" :img="store_header"
                                    text="门头照片" :isDisabled="isIncome" />
                                <el-input style="display:none" v-model="form.store_header" :disabled="isIncome" />
                            </el-form-item>
                        </el-col>
                        <el-col :span="8">
                            <el-form-item label="经营场所-含收银台：" prop="store_cashier">
                                <c-upload ref="uploadStoreCashierRef" @success="uploadStoreCashier" :img="store_cashier"
                                    text="场所照片" :isDisabled="isIncome" />
                                <el-input style="display:none" v-model="form.store_cashier" :disabled="isIncome" />
                            </el-form-item>
                        </el-col>
                        <el-col :span="8">
                            <el-form-item label="店内照片：" prop="store_indoor">
                                <c-upload ref="uploadStoreIndoorRef" @success="uploadStoreIndoor" :img="store_indoor"
                                    text="店内照片" :isDisabled="isIncome" />
                                <el-input style="display:none" v-model="form.store_indoor" :disabled="isIncome" />
                            </el-form-item>
                        </el-col>
                    </el-row>
                </div>
                <div id="step-three" class="form-box" v-if="step == 3">
                    <template v-if="form.business_type == 1 || form.business_type == 0">
                        <el-row>
                            <el-col :span="12">
                                <el-form-item label="结算账户类型：">
                                    <el-radio-group v-model="form.account_type" :disabled="isIncome">
                                        <el-radio :label="0">对私结算</el-radio>
                                        <el-radio :label="1">对公结算</el-radio>
                                    </el-radio-group>
                                </el-form-item>
                            </el-col>
                        </el-row>
                        <template v-if="form.account_type == 0">
                            <el-row>
                                <el-col :span="12">
                                    <el-form-item label="结算类型：">
                                        <el-radio-group v-model="form.settlement_type" :disabled="isIncome">
                                            <el-radio :label="0">法人结算</el-radio>
                                            <el-radio :label="1">授权非法人结算</el-radio>
                                        </el-radio-group>
                                    </el-form-item>
                                </el-col>
                            </el-row>
                            <el-row>
                                <el-col :span="12">
                                    <el-form-item label="银行卡号面：" prop="settlement_card_front">
                                        <c-upload ref="settlementCardFrontRef" @success="settlementCardFront"
                                            :img="settlement_card_front" text="银行卡号面" />
                                        <el-input v-model="form.settlement_card_front" style="display:none"
                                            :disabled="isIncome" :isDisabled="isIncome" />
                                    </el-form-item>
                                </el-col>
                                <el-col :span="12">
                                    <el-form-item label="银行卡反面：" prop="settlement_card_reverse">
                                        <c-upload ref="settlementCardReverseRef" @success="settlementCardReverse"
                                            :img="settlement_card_reverse" text="银行卡反面" />
                                        <el-input v-model="form.settlement_card_reverse" style="display:none"
                                            :disabled="isIncome" :isDisabled="isIncome" />
                                    </el-form-item>
                                </el-col>
                            </el-row>
                            <template v-if="form.settlement_type == 1">
                                <el-row>
                                    <el-col :span="12">
                                        <el-form-item label="结算人身份证正面：" prop="settlement_person_id_card_front">
                                            <c-upload ref="settlementPersonIdCardFrontRef"
                                                @success="settlementPersonIdCardFront"
                                                :img="settlement_person_id_card_front" text="身份证正面" />
                                            <el-input v-model="form.settlement_person_id_card_front"
                                                style="display:none" :disabled="isIncome" :isDisabled="isIncome" />
                                        </el-form-item>
                                    </el-col>
                                    <el-col :span="12">
                                        <el-form-item label="结算人身份证反面：" prop="settlement_person_id_card_reverse">
                                            <c-upload ref="settlementPersonIdCardReverseRef"
                                                @success="settlementPersonIdCardReverse"
                                                :img="settlement_person_id_card_reverse" text="身份证反面" />
                                            <el-input v-model="form.settlement_person_id_card_reverse"
                                                style="display:none" :disabled="isIncome" :isDisabled="isIncome" />
                                        </el-form-item>
                                    </el-col>
                                </el-row>
                                <el-row>
                                    <el-col :span="12">
                                        <el-form-item label="非法人结算授权函：" prop="authorization_letter">
                                            <c-upload ref="authorizationLetterRef" @success="authorizationLetter"
                                                :img="authorization_letter" text="结算授权函" />
                                            <el-input v-model="form.authorization_letter" style="display:none"
                                                :disabled="isIncome" :isDisabled="isIncome" />
                                        </el-form-item>
                                    </el-col>
                                    <el-col :span="12">
                                        <el-form-item label="法人与收款人授权书合照（邮政必填）：">
                                            <c-upload ref="holdAuthorizationLetterRef"
                                                @success="holdAuthorizationLetter" :img="hold_authorization_letter"
                                                text="法人与收款人授权书合照" />
                                            <el-input v-model="form.hold_authorization_letter" style="display:none"
                                                :disabled="isIncome" :isDisabled="isIncome" />
                                        </el-form-item>
                                    </el-col>
                                </el-row>
                                <el-row>
                                    <el-col :span="12">
                                        <el-form-item label="结算账户名：" prop="settlement_account_name">
                                            <el-input v-model="form.settlement_account_name" clearable
                                                :disabled="isIncome" />
                                        </el-form-item>
                                    </el-col>
                                    <el-col :span="12">
                                        <el-form-item label="结算人身份证号码：" prop="settlement_person_id_card_no">
                                            <el-input v-model="form.settlement_person_id_card_no" maxlength="18"
                                                clearable :disabled="isIncome" />
                                        </el-form-item>
                                    </el-col>
                                </el-row>
                                <el-row>
                                    <el-col :span="12">
                                        <el-form-item label="身份证有效期：" prop="settlementLegalPersonIdCardDate">
                                            <el-date-picker v-model="form.settlementLegalPersonIdCardDate"
                                                type="daterange" range-separator="至" start-placeholder="开始时间"
                                                end-placeholder="结束时间" :disabled="isIncome" />
                                        </el-form-item>
                                    </el-col>
                                    <el-col :span="2">
                                        <el-form-item label-width="30px">
                                            <el-checkbox v-model="form.settlement_person_id_card_expiry_forever"
                                                label="长期" size="large" :disabled="isIncome" />
                                        </el-form-item>
                                    </el-col>
                                </el-row>
                            </template>
                        </template>
                        <template v-if="form.account_type == 1">
                            <el-row>
                                <el-col :span="12">
                                    <el-form-item label="开户许可证：" prop="permit_for_opening_bank_account">
                                        <c-upload ref="permitForOpeningBankAccountRef"
                                            @success="permitForOpeningBankAccount"
                                            :img="permit_for_opening_bank_account" text="开户许可证" />
                                        <el-input v-model="form.permit_for_opening_bank_account" style="display:none"
                                            :disabled="isIncome" :isDisabled="isIncome" />
                                    </el-form-item>
                                </el-col>
                            </el-row>
                        </template>
                    </template>
                    <template v-if="form.business_type == 2">
                        <el-row>
                            <el-col :span="12">
                                <el-form-item label="银行卡号面：" prop="settlement_card_front">
                                    <c-upload ref="settlementCardFrontRef" @success="settlementCardFront"
                                        :img="settlement_card_front" text="银行卡号面" :isDisabled="isIncome" />
                                    <el-input v-model="form.settlement_card_front" style="display:none"
                                        :disabled="isIncome" />
                                </el-form-item>
                            </el-col>
                            <el-col :span="12">
                                <el-form-item label="银行卡反面：" prop="settlement_card_reverse">
                                    <c-upload ref="settlementCardReverseRef" @success="settlementCardReverse"
                                        :img="settlement_card_reverse" text="银行卡反面" />
                                    <el-input v-model="form.settlement_card_reverse" style="display:none"
                                        :disabled="isIncome" :isDisabled="isIncome" />
                                </el-form-item>
                            </el-col>
                        </el-row>
                    </template>
                    <el-row>
                        <el-col :span="12">
                            <el-form-item label="补充材料：">
                                <c-upload ref="holdIdCardBankCardRef" @success="holdIdCardBankCard"
                                    :img="hold_id_card_bank_card" text="收款人手持身份证照（银行专用）" />
                                <el-input v-model="form.hold_id_card_bank_card" style="display:none"
                                    :disabled="isIncome" />
                            </el-form-item>
                        </el-col>
                    </el-row>
                    <el-row>
                        <el-col :span="12">
                            <el-form-item label="结算银行账号：" prop="settlement_account_no">
                                <el-input v-model="form.settlement_account_no" maxlength="19" clearable
                                    :disabled="isIncome" />
                            </el-form-item>
                        </el-col>
                    </el-row>
                    <el-row>
                        <el-col :span="12">
                            <el-form-item label="开户行：" prop="settlement_account_bank_code">
                                <el-select v-model="form.settlement_account_bank_code" clearable :disabled="isIncome"
                                    @change="settlemntAccountBankChange" value-key="id">
                                    <el-option v-for="bank in bankList" :key="bank.id" :label="bank.name"
                                        :value="bank.id" />
                                </el-select>
                            </el-form-item>
                        </el-col>
                        <el-col :span="12">
                            <el-form-item label="银行预留手机号：" prop="settlement_account_phone">
                                <el-input v-model="form.settlement_account_phone" maxlength="11" clearable
                                    :disabled="isIncome" />
                            </el-form-item>
                        </el-col>
                    </el-row>
                    <el-row>
                        <el-col :span="12">
                            <el-form-item label="开户行城市：" prop="settlement_account_bank_province_code">
                                <c-cascader @success="settlementAccountArea"
                                    :defaultOption="form.settlement_account_area" @change="loadBranchBanck"
                                    :disabled="isIncome">
                                </c-cascader>
                                <el-input v-model="this.form.settlement_account_bank_province_code"
                                    style="display:none" />
                            </el-form-item>
                        </el-col>
                    </el-row>
                    <el-row>
                        <el-col :span="12">
                            <el-form-item label="结算户开户支行：" prop="settlement_account_bank_branch_no">
                                <el-select v-model="form.settlement_account_bank_branch_no" filterable clearable
                                    :disabled="isIncome">
                                    <el-option v-for="(bank, index) in branchBanks" :key="index" :label="bank.name"
                                        :value="bank.hanghao" />
                                </el-select>
                            </el-form-item>
                        </el-col>
                    </el-row>
                </div>
            </el-form>
        </el-main>
        <el-footer class="footer">
            <el-button class="footer-btn" type="primary" @click="handleBack">
                退出
            </el-button>
            <el-button class="footer-btn" type="primary" @click="preStep" v-if="step != 1">
                上一步
            </el-button>
            <el-button class="footer-btn" type="primary" @click="nextStep" v-if="step != 3">
                下一步
            </el-button>
            <el-button class="footer-btn" type="primary" @click="submit" v-if="step == 3 && !isIncome">
                提交
            </el-button>
        </el-footer>
    </el-container>
</template>

<script>
import cUpload from '@/components/CustomUpload'
import cCascader from '@/components/CustomCascader'
import { ElMessage } from 'element-plus'
import { timeFormat } from '@/func/time'
import { phone, identificationNumber, bankNumber } from '@/func/validatorFunc'

export default {
    components: {
        cUpload,
        cCascader
    },
    data() {
        return {
            step: 1, //步骤
            uploadAction: this.$config.http + '/agent/merchants/upload', // 上传url

            /**
             * step 1
             */
            companyTypes: [ // 公司类型列表
                { id: 0, type: '请选择' },
                { id: 1, type: '个体工商户' },
                { id: 2, type: '有限责任公司' },
                { id: 3, type: '非公司企业法人' },
                { id: 4, type: '私营合伙企业' },
                { id: 5, type: '私营独资企业' },
                { id: 6, type: '股份有限责任公司' },
            ],

            salesmenOptions: [], //业务员列表
            mccOptions: [],
            mccProps: {
                label: 'name',
                value: 'code',
                children: 'children'
            },
            branchBanks: [],
            bankList: [],
            settlemntAccountBank: '',
            isIncome: false,

            /**
             * 图片地址
             */
            license_pic: '',
            legal_person_holding_id_card: '',
            legal_person_id_card_front: '',
            legal_person_id_card_reverse: '',
            store_indoor: '',
            store_header: '',
            store_cashier: '',
            settlement_card_front: '',
            settlement_card_reverse: '',
            settlement_person_id_card_front: '',
            settlement_person_id_card_reverse: '',
            authorization_letter: '',
            permit_for_opening_bank_account: '',
            hold_id_card_bank_card: '',

            /**
             * form表单
             */
            form: {
                /**
                 * step 1
                 */
                salesman_id: '',
                merchant_name: '',
                business_type: 0,
                company_type: 1,
                licenseDate: [],
                license_effect_date: '', // 营业执照有效期 起
                license_expiry_date: '', // 营业执照有效期 止
                license_pic: '',
                license_expiry_forever: false,
                merchant_company: '',
                license_no: '',
                legalPersonIdCardDate: [],
                legal_person_holding_id_card: '',
                legal_person_id_card_effect_date: '', // 法人证件有效期 起
                legal_person_id_card_expiry_date: '', // 法人证件有效期 止
                legal_person_name: '',
                legal_person_id_card_no: '',
                legal_person_id_card_expiry_forever: false,

                /**
                 * step 2
                 */
                merchant_alias: '',
                mcc_codes: [],
                mcc_code: '',
                business_city: [],
                province_code: '',
                city_code: '',
                district_code: '',
                business_address: '',
                business_scope: '',
                contact_person_name: '',
                contact_person_phone: '',
                store_header: '',
                store_cashier: '',
                store_indoor: '',

                /**
                 * step 3 
                 */
                account_type: 0,
                settlement_type: 0,
                permit_for_opening_bank_account: '',
                settlement_card_front: '',
                settlement_card_reverse: '',
                settlement_person_id_card_front: '',
                settlement_person_id_card_reverse: '',
                authorization_letter: '',
                settlement_account_name: '',
                settlement_person_id_card_no: '',
                settlementLegalPersonIdCardDate: [],
                settlement_person_id_card_effect_date: '',
                settlement_person_id_card_expiry_date: '',
                settlement_person_id_card_expiry_forever: false,
                settlement_account_no: '',
                settlement_account_bank_code: '',
                settlement_account_phone: '',
                settlement_account_area: [],
                settlement_account_bank_province_code: '',
                settlement_account_bank_city_code: '',
                settlement_account_bank_district_code: '',
                settlement_account_bank_branch_no: '',
                hold_authorization_letter: '',
                hold_id_card_bank_card: ''
            },
            licenseDate: [],
            legalPersonIdCardDate: [],
            /**
             * 验证
             */
            rules: {
                /**
                 * step 1
                 */
                salesman_id: [
                    { required: true, message: '此项为必填' },
                ],
                merchant_name: [
                    { required: true, message: '此项为必填' },
                    { validator: this.validateMerchantName, trigger: 'change' }
                ],
                license_pic: [
                    { required: true, message: '此项为必填' },
                ],
                company_type: [
                    { required: true, message: '此项为必填' },
                ],
                licenseDate: [
                    { required: true, message: '此项为必填' },
                ],
                merchant_company: [
                    { required: true, message: '此项为必填' },
                ],
                license_no: [
                    { required: true, message: '此项为必填' },
                    { validator: identificationNumber, trigger: 'change' }
                ],
                legal_person_holding_id_card: [
                    { required: true, message: '此项为必填' },
                ],
                legal_person_id_card_front: [
                    { required: true, message: '此项为必填' },
                ],
                legal_person_id_card_reverse: [
                    { required: true, message: '此项为必填' },
                ],
                legal_person_name: [
                    { required: true, message: '此项为必填' },
                ],
                legal_person_id_card_no: [
                    { required: true, message: '此项为必填' },
                    { validator: identificationNumber, trigger: 'change' }
                ],
                legalPersonIdCardDate: [
                    { required: true, message: '此项为必填' },
                ],

                /**
                 * step 2
                 */
                merchant_alias: [
                    { required: true, message: '此项为必填' },
                ],
                mcc_codes: [
                    { required: true, message: '此项为必填' },
                ],
                business_city: [
                    { required: true, message: '此项为必填' },
                ],
                contact_person_name: [
                    { required: true, message: '此项为必填' },
                ],
                contact_person_phone: [
                    { required: true, message: '此项为必填' },
                    { validator: phone, trigger: 'change' },
                ],
                business_address: [
                    { required: true, message: '此项为必填' },
                ],
                business_scope: [
                    { required: true, message: '此项为必填' },
                ],
                store_header: [
                    { required: true, message: '此项为必填' },
                ],
                store_cashier: [
                    { required: true, message: '此项为必填' },
                ],
                store_indoor: [
                    { required: true, message: '此项为必填' },
                ],

                /**
                 * step 3
                 */
                permit_for_opening_bank_account: [
                    { required: true, message: '此项为必填' },
                ],
                settlement_card_front: [
                    { required: true, message: '此项为必填' },
                ],
                settlement_card_reverse: [
                    { required: true, message: '此项为必填' },
                ],
                settlement_person_id_card_front: [
                    { required: true, message: '此项为必填' },
                ],
                settlement_person_id_card_reverse: [
                    { required: true, message: '此项为必填' },
                ],
                authorization_letter: [
                    { required: true, message: '此项为必填' },
                ],
                settlement_account_name: [
                    { required: true, message: '此项为必填' },
                ],
                settlement_person_id_card_no: [
                    { required: true, message: '此项为必填' },
                    { validator: identificationNumber, trigger: 'change' }
                ],
                settlementLegalPersonIdCardDate: [
                    { required: true, message: '此项为必填' },
                ],
                settlement_account_no: [
                    { required: true, message: '此项为必填' },
                    { validator: bankNumber, trigger: 'change' },
                ],
                settlement_account_bank_code: [
                    { required: true, message: '此项为必填' },
                ],
                settlement_account_phone: [
                    { required: true, message: '此项为必填' },
                    { validator: phone, trigger: 'change' },
                ],
                settlement_account_bank_province_code: [
                    { required: true, message: '此项为必填' },
                ],
                settlement_account_bank_city_code: [
                    { required: true, message: '此项为必填' },
                ],
                settlement_account_bank_branch_no: [
                    { required: true, message: '此项为必填' },
                ]
            },
            isEdit: false,
            isLoad: false
        }
    },
    created() {
        this.loadSalesmen()
        if (this.$route.query.id) {
            this.loadMerchantInfo(this.$route.query.id)
            this.loadMcc()
            this.loadBankList()
        }
    },
    methods: {
        loadMerchantInfo(merchantId) {
            this.isEdit = true
            this.isLoad = true
            this.$api.merchant.select(merchantId).then(r => {
                if (r.status == 403 && r.error.code == 'unauthorized') {
                    ElMessage({ message: '无权限', type: 'error' })
                    this.isLoad = false
                    return
                }
                const data = r.data
                this.form = data
                this.isIncome = data.isIncome

                this.$nextTick(() => {
                    if (data.license_pic_url && this.$refs['licenseRef']) {
                        this.$refs.licenseRef.setImgUrl(data.license_pic_url)
                    }
                    if (data.legal_person_id_card_front_url && this.$refs['uploadIdCardFrontRef']) {
                        this.$refs.uploadIdCardFrontRef.setImgUrl(data.legal_person_id_card_front_url)
                    }
                    if (data.legal_person_holding_id_card && this.$refs['holdingIdCardRef']) {
                        this.$refs.holdingIdCardRef.setImgUrl(data.legal_person_holding_id_card_url)
                    }
                    if (data.legal_person_id_card_reverse_url && this.$refs['uploadIdCardReverseRef']) {
                        this.$refs.uploadIdCardReverseRef.setImgUrl(data.legal_person_id_card_reverse_url)
                    }
                })

                this.form.account_type = parseInt(data.account_type)
                this.form.settlement_type = parseInt(data.settlement_type)
                this.form.business_type = parseInt(data.business_type)
                this.form.mcc_codes = data.mcc_code
                this.form.mcc_code = data.mcc_code

                const settlement_account_area = []
                settlement_account_area[0] = data.settlement_account_bank_province_code + ''
                settlement_account_area[1] = data.settlement_account_bank_city_code + ''
                settlement_account_area[2] = data.settlement_account_bank_district_code + ''
                this.form.settlement_account_area = settlement_account_area

                const business_city = []
                business_city[0] = data.province_code + ''
                business_city[1] = data.city_code + ''
                business_city[2] = data.district_code + ''
                this.form.business_city = business_city

                const licenseDate = []
                licenseDate[0] = new Date(data.license_effect_date)
                licenseDate[1] = new Date(data.license_expiry_date)
                if (data.license_expiry_forever) {
                    this.form.license_expiry_forever = true
                }
                this.form.licenseDate = licenseDate

                const legalPersonIdCardDate = []
                legalPersonIdCardDate[0] = new Date(data.legal_person_id_card_effect_date)
                legalPersonIdCardDate[1] = new Date(data.legal_person_id_card_expiry_date)
                if (data.legal_person_id_card_expiry_forever) {
                    this.form.legal_person_id_card_expiry_forever = true
                }
                this.form.legalPersonIdCardDate = legalPersonIdCardDate

                const settlementLegalPersonIdCardDate = []
                settlementLegalPersonIdCardDate[0] = new Date(data.settlement_person_id_card_effect_date)
                settlementLegalPersonIdCardDate[1] = new Date(data.settlement_person_id_card_expiry_date)
                if (data.settlement_person_id_card_expiry_forever) {
                    this.form.settlement_person_id_card_expiry_forever = true
                }
                this.form.settlementLegalPersonIdCardDate = settlementLegalPersonIdCardDate

                this.license_pic = data.license_pic_url
                this.legal_person_id_card_front = data.legal_person_id_card_front_url
                this.legal_person_id_card_reverse = data.legal_person_id_card_reverse_url

                this.store_indoor = data.store_indoor_url
                this.store_header = data.store_header_url
                this.store_cashier = data.store_cashier_url

                this.settlement_card_front = data.settlement_card_front_url
                this.settlement_card_reverse = data.settlement_card_reverse_url
                this.settlement_person_id_card_front = data.settlement_person_id_card_front_url
                this.settlement_person_id_card_reverse = data.settlement_person_id_card_reverse_url
                this.authorization_letter = data.authorization_letter_url
                this.permit_for_opening_bank_account = data.permit_for_opening_bank_account_url

                this.isLoad = false

                this.form.settlement_account_bank_code = parseInt(this.form.settlement_account_bank_code)
                this.settlemntAccountBankChange(this.form.settlement_account_bank_code)
                this.loadBranchBanks(
                    this.form.settlement_account_bank_code,
                    this.form.settlement_account_bank_province_code,
                    this.form.settlement_account_bank_city_code,
                    this.settlemntAccountBank
                )
            })
        },

        validateMerchantName(rule, value, callback) {
            if (this.form.business_type !== 2 && value !== this.form.merchant_company) {
                callback(new Error('商户名称必须与营业执照名相同'));
            } else {
                callback();
            }
        },

        loadSalesmen() {
            this.$api.salesman.list({ pageSize: 999, page: 1 }).then((r) => {
                this.salesmenOptions = r.data
            })
        },
        loadMcc() {
            if (this.mccOptions.length <= 0) {
                this.$api.mcc.list({}).then((r) => {
                    this.mccOptions = r.data.data
                })
            }
        },
        loadBranchBanks(bankId, provinceCode, cityCode, bankName) {
            const params = {
                bank_id: bankId,
                province_code: provinceCode,
                city_code: cityCode,
                name: bankName,
                pageSize: 999
            }
            this.$api.banks.branchBanks(params).then((r) => {
                this.branchBanks = r.data.data
            })
        },
        loadBankList() {
            if (this.bankList.length <= 0) {
                this.$api.banks.list().then((r) => {
                    this.bankList = r.data.data
                })
            }
        },
        settlemntAccountBankChange(val) {
            if (val) {
                let currentItem = {}
                currentItem = this.bankList.find((item) => {
                    return item.id == val
                })

                this.settlemntAccountBank = currentItem ? currentItem.name : ""
            }
        },
        loadBranchBanck() {
            this.loadBranchBanks(
                this.form.settlement_account_bank_code,
                this.form.settlement_account_bank_province_code,
                this.form.settlement_account_bank_city_code,
                this.settlemntAccountBank
            )
        },

        /**
         * 提交表单
         */
        submit() {
            this.$refs.form.validate((validate) => {
                if (validate) {
                    //营业执照有效期
                    // const licenseDate = timeFormat(this.form.licenseDate)
                    this.form.license_effect_date = timeFormat(this.form.licenseDate[0])
                    this.form.license_expiry_date = timeFormat(this.form.licenseDate[1])
                    this.form.legal_person_id_card_expiry_forever = this.form.legal_person_id_card_expiry_forever ? 1 : 0

                    //法人身份证有效期
                    this.form.legal_person_id_card_effect_date = timeFormat(this.form.legalPersonIdCardDate[0])
                    this.form.legal_person_id_card_expiry_date = timeFormat(this.form.legalPersonIdCardDate[1])
                    this.form.license_expiry_forever = this.form.license_expiry_forever ? 1 : 0

                    //非法人结算身份证有效期
                    this.form.settlement_person_id_card_effect_date = timeFormat(this.form.settlementLegalPersonIdCardDate[0])
                    this.form.settlement_person_id_card_expiry_date = timeFormat(this.form.settlementLegalPersonIdCardDate[1])
                    this.form.settlement_person_id_card_expiry_forever = this.form.settlement_person_id_card_expiry_forever ? 1 : 0

                    if (this.isEdit) {
                        if (this.form.mcc_codes instanceof Array) {
                            this.form.mcc_code = this.form.mcc_codes[1]
                        }
                        this.form._method = 'put'
                        this.$api.merchant.edit(this.form.id, this.form).then((r) => {
                            this.resultData(r)
                        })
                    } else {
                        this.form.mcc_code = this.form.mcc_codes[1]
                        this.$api.merchant.create(this.form).then((r) => {
                            this.resultData(r)
                        })
                    }
                } else {
                    console.log('验证失败')
                }
            })
        },
        resultData(data) {
            if (data && (data.status == 200)) {
                ElMessage({ message: this.isEdit ? '修改成功' : '创建成功', type: 'success' })
                this.$router.push({ path: '/merchant/list' })
            } else {
                ElMessage({ message: data.error.message, type: 'error' })
            }
        },
        /**
         * 下一步
         */
        nextStep() {
            this.$refs.form.validate((validate) => {
                if (validate) {
                    this.step += 1
                    if (this.step > 3) {
                        this.step = 3
                    }

                    switch (this.step) {
                        case 2:
                            this.loadMcc()
                            if (this.isEdit) {
                                this.$nextTick(() => {
                                    this.$refs.businessCityRef.setDefaultOption(this.form.business_city)
                                    this.$refs.uploadStoreIndoorRef.setImgUrl(this.form.store_indoor_url)
                                    this.$refs.uploadStoreHeaderRef.setImgUrl(this.form.store_header_url)
                                    this.$refs.uploadStoreCashierRef.setImgUrl(this.form.store_cashier_url)
                                })
                            }
                            break;
                        case 3:
                            // this.loadBranchBanks()
                            this.loadBankList()
                            if (this.isEdit) {
                                this.$nextTick(() => {
                                    if (this.form.account_type == 1) {
                                        this.$refs.permitForOpeningBankAccountRef.setImgUrl(this.form.permit_for_opening_bank_account_url)
                                    } else {
                                        if (this.$refs['settlementPersonIdCardFrontRef']) {
                                            this.$refs.settlementPersonIdCardFrontRef.setImgUrl(this.form.settlement_person_id_card_front_url)
                                        }
                                        if (this.$refs['settlementPersonIdCardReverseRef']) {
                                            this.$refs.settlementPersonIdCardReverseRef.setImgUrl(this.form.settlement_person_id_card_reverse_url)
                                        }

                                        if (this.form.settlement_type == 1) {
                                            this.$refs.settlementCardFrontRef.setImgUrl(this.form.settlement_card_front_url)
                                            this.$refs.settlementCardReverseRef.setImgUrl(this.form.settlement_card_reverse_url)
                                            this.$refs.authorizationLetterRef.setImgUrl(this.form.authorization_letter_url)
                                            this.$refs.holdAuthorizationLetterRef.setImgUrl(this.form.hold_authorization_letter_url)
                                        }

                                        if (this.$refs['holdIdCardBankCardRef']) {
                                            this.$refs.holdIdCardBankCardRef.setImgUrl(this.form.hold_id_card_bank_card_url)
                                        }
                                    }
                                })
                            }
                            break;
                    }
                }
            })
        },
        /**
         * 退出
         */
        handleBack() {
            this.$router.back();
        },

        /**
         * 上一步
         */
        preStep() {
            this.step -= 1
            if (this.step < 1) {
                this.step = 1
            }
        },
        /**
         * 经营地址
         */
        businessCity(value) {
            this.form.province_code = value[0]
            this.form.city_code = value[1]
            this.form.district_code = value[2]
            this.form.business_city = value
        },
        /**
         * 开户城市
         * @param {*} value 
         */
        settlementAccountArea(value) {
            this.form.settlement_account_bank_province_code = value[0]
            this.form.settlement_account_bank_city_code = value[1]
            this.form.settlement_account_bank_district_code = value[2]
            this.form.settlement_account_area = value
        },
        setImageInfo(name, imgId, imgUrl) {
            this.form[name] = imgId
            this[name] = imgUrl
        },
        /**
         * 营业执照文件
         * @param {*} imgUrl 
         */
        uploadBusinessLicense(imgId, imgUrl, file) {
            this.setImageInfo('license_pic', imgId, imgUrl)
            const fileBase64 = this.getBase64(file)
            fileBase64.then(async (fileBase64String) => {
                const data = await this.ocrDiscern(fileBase64String, "LICENSE")
                // 替换license_name中的()为中文括号（）
                const licenseNameWithChineseBrackets = data.license_name.replace(/\(/g, '（').replace(/\)/g, '）');
                this.form['license_no'] = data.license_no
                this.form['merchant_company'] = licenseNameWithChineseBrackets
                this.form['merchant_name'] = licenseNameWithChineseBrackets
                this.form['business_address'] = data.license_address
                this.form.licenseDate[0] = new Date(data.register_date)
                this.form.licenseDate[1] = new Date(data.expire_date)
                if (data.expire_date == '9999-12-31') {
                    this.form.license_expiry_forever = true
                } else {
                    this.form.license_expiry_forever = false
                }
                if (data.license_type != '个体工商户') {
                    this.form.company_type = 2
                } else {
                    this.form.company_type = 1
                }

            })
        },
        /**
         * 法人手持身份证
         * @param {*} imgUrl 
         */
        uploadHoldingIdCard(imgId, imgUrl) {
            this.setImageInfo('legal_person_holding_id_card', imgId, imgUrl)

        },
        /**
         * 法人身份证正面
         * @param {*} imgUrl 
         */
        async uploadIdCardFront(imgId, imgUrl, file) {
            this.setImageInfo('legal_person_id_card_front', imgId, imgUrl)
            const fileBase64 = this.getBase64(file)
            fileBase64.then(async (fileBase64String) => {
                const data = await this.ocrDiscern(fileBase64String, "ID_CARD", 'front')
                this.form.legal_person_name = data.name
                this.form.legal_person_id_card_no = data.id_card_no
            })
        },
        /**
         * 法人身份证反面
         * @param {*} imgUrl 
         */
        async uploadIdCardReverse(imgId, imgUrl, file) {
            this.setImageInfo('legal_person_id_card_reverse', imgId, imgUrl)
            const fileBase64 = this.getBase64(file)
            fileBase64.then(async (fileBase64String) => {
                const data = await this.ocrDiscern(fileBase64String, "ID_CARD", 'back')
                this.form.legalPersonIdCardDate[0] = new Date(data.is_use_date)
                this.form.legalPersonIdCardDate[1] = new Date(data.expire_date)
                if (data.expire_date == '9999-12-31') {
                    this.form.legal_person_id_card_expiry_forever = true
                } else {
                    this.form.legal_person_id_card_expiry_forever = false
                }
            })

        },
        /**
         * 店内照片
         * @param {*} imgUrl 
         */
        uploadStoreIndoor(imgId, imgUrl) {
            this.setImageInfo('store_indoor', imgId, imgUrl)
        },
        /**
         * 门头照片
         * @param {*} imgUrl 
         */
        uploadStoreHeader(imgId, imgUrl) {
            this.setImageInfo('store_header', imgId, imgUrl)
        },
        /**
         * 收银台照片
         * @param {*} imgUrl 
         */
        uploadStoreCashier(imgId, imgUrl) {
            this.setImageInfo('store_cashier', imgId, imgUrl)
        },
        /**
         * 银行卡正面
         * @param {*} imgUrl 
         */
        async settlementCardFront(imgId, imgUrl, file) {
            this.setImageInfo('settlement_card_front', imgId, imgUrl)
            const fileBase64 = this.getBase64(file)
            fileBase64.then(async (fileBase64String) => {
                const data = await this.ocrDiscern(fileBase64String, "BANK_CARD")
                this.form.settlement_account_no = data.bank_card_number
            })
        },
        /**
         * 银行卡反面
         * @param {*} imgUrl 
         */
        settlementCardReverse(imgId, imgUrl) {
            this.setImageInfo('settlement_card_reverse', imgId, imgUrl)
        },
        /**
         * 结算人身份证正面
         * @param {*} imgUrl 
         */
        async settlementPersonIdCardFront(imgId, imgUrl, file) {
            this.setImageInfo('settlement_person_id_card_front', imgId, imgUrl)
            const fileBase64 = this.getBase64(file)
            fileBase64.then(async (fileBase64String) => {
                const data = await this.ocrDiscern(fileBase64String, "ID_CARD", 'front')
                this.form.settlement_account_name = data.name
                this.form.settlement_person_id_card_no = data.id_card_no
            })
        },
        /**
         * 结算人身份证反面
         * @param {*} imgUrl 
         */
        async settlementPersonIdCardReverse(imgId, imgUrl, file) {
            this.setImageInfo('settlement_person_id_card_reverse', imgId, imgUrl)
            const fileBase64 = this.getBase64(file)
            fileBase64.then(async (fileBase64String) => {
                const data = await this.ocrDiscern(fileBase64String, "ID_CARD", 'back')
                this.form.settlementLegalPersonIdCardDate[0] = new Date(data.is_use_date)
                this.form.settlementLegalPersonIdCardDate[1] = new Date(data.expire_date)
                if (data.expire_date == '9999-12-31') {
                    this.form.settlement_person_id_card_expiry_forever = true
                } else {
                    this.form.settlement_person_id_card_expiry_forever = false
                }
            })
        },
        /**
         * 非法人结算授权函
         * @param {*} imgUrl 
         */
        authorizationLetter(imgId, imgUrl) {
            this.setImageInfo('authorization_letter', imgId, imgUrl)
        },
        /**
         * 法人与收款人授权书合照
         * @param {*} imgId 
         * @param {*} imgUrl 
         */
        holdAuthorizationLetter(imgId, imgUrl) {
            this.setImageInfo('hold_authorization_letter', imgId, imgUrl)
        },
        /**
         * 收款人手持身份证收款卡照
         * @param {*} imgId 
         * @param {*} imgUrl 
         */
        holdIdCardBankCard(imgId, imgUrl) {
            this.setImageInfo('hold_id_card_bank_card', imgId, imgUrl)
        },
        /**
         * 开户许可证
         * @param {*} imgUrl 
         */
        permitForOpeningBankAccount(imgId, imgUrl) {
            this.setImageInfo('permit_for_opening_bank_account', imgId, imgUrl)
        },
        /**
         * 图片识别
         * @param {*} file 
         * @param {*} type 
         */
        async ocrDiscern(file, discern_type, id_card_side) {
            const params = {
                file: file,
                discern_type: discern_type,
                id_card_side: id_card_side ? id_card_side : ''
            }
            const response = await this.$api.ocrDiscern(params, { headers: { 'Content-Type': 'multipart/form-data' } })
            const data = response.data
            if (data.status == 200) {
                return data.data
            } else {
                ElMessage({ message: data.error.meesage, type: 'error' })
            }
        },
        /**
         * file转base64
         * @param {*} file 
         */
        getBase64(file) {
            // 直接将处理结果返回
            return new Promise((resolve, reject) => {
                let reader = new FileReader();
                let fileResult = "";
                reader.readAsDataURL(file);
                reader.onload = () => {
                    fileResult = reader.result.toString();
                };
                reader.onerror = (error) => {
                    reject(error);
                };
                reader.onloadend = () => {
                    resolve(fileResult);
                };
            });
        }
    }
}
</script>

<style lang="less" scoped>
.container {
    width: 80%;
    margin: 0 auto;

    .el-progress--line {
        margin-bottom: 15px;
        width: 250px;
    }

    .footer {
        text-align: center;
        background-color: transparent;

        .footer-btn {
            display: inline-block;
            margin-left: 15px;
        }
    }
}
</style>
