<template>
    <div class="form-box">
        <el-form :model="form" label-width="100px" label-position="right">
            <el-row :gutter="16">
                <el-col :xs="24" :sm="12" :md="8" :lg="8">
                    <el-form-item label="交易流水号:" style="width: 100%">
                        <el-input v-model="form.trade_no" placeholder="" clearable />
                    </el-form-item>
                </el-col>
                <el-col :xs="24" :sm="12" :md="8" :lg="8">
                    <el-form-item label="商户名称:" style="width: 100%">
                        <el-input v-model="form.merchant_name" placeholder="" clearable />
                    </el-form-item>
                </el-col>
                <!-- <el-col :span="5">
                    <el-form-item label="代理商名称:">
                        <el-input v-model="form.agent_name" placeholder="" />
                    </el-form-item>
                </el-col> -->

                <el-col :xs="24" :sm="12" :md="8" :lg="8">
                    <el-form-item label="门店名称:" style="width: 100%">
                        <el-input v-model="form.store_name" placeholder="" clearable />
                    </el-form-item>
                </el-col>

                <el-col :xs="24" :sm="12" :md="8" :lg="8">
                    <el-form-item label="商户号:" style="width: 100%">
                        <el-input v-model="form.merchant_no" placeholder="" clearable />
                    </el-form-item>
                </el-col>
                <el-col :xs="24" :sm="12" :md="8" :lg="8">
                    <el-form-item label="商户订单号:" style="width: 100%">
                        <el-input v-model="form.out_trade_no" placeholder="" clearable />
                    </el-form-item>
                </el-col>
                <el-col :xs="24" :sm="12" :md="8" :lg="8">
                    <el-form-item label="支付金额:" style="width: 100%">
                        <el-input v-model="form.pay_amount" placeholder="" clearable />
                    </el-form-item>
                </el-col>

                <!-- <el-col :span="4">
                    <el-form-item label=" 接 口 类 型:">
                        <el-select  clearable>
                            <el-option :value="0" label="正扫" />
                            <el-option :value="1" label="反扫" />
                        </el-select>
                    </el-form-item>
                </el-col> -->
                <el-col :xs="24" :sm="12" :md="8" :lg="8">
                    <el-form-item label="订单状态:" style="width: 100%">
                        <el-select v-model="form.status" clearable style="width: 100%">
                            <el-option value="default" label="交易完成" />
                            <el-option value="3" label="支付成功" />
                            <el-option value="fail" label="支付失败" />
                            <el-option value="5" label="部分退款" />
                            <el-option value="6" label="已退款" />
                        </el-select>
                    </el-form-item>
                </el-col>

                <el-col :xs="24" :sm="12" :md="8" :lg="8">
                    <el-form-item label="支付渠道:" style="width: 100%">
                        <el-select clearable v-model="form.payment_channel_id" style="width: 100%">
                            <el-option v-for="item in channelList" :key="item" :value="item.id" :label="item.name" />
                        </el-select>
                    </el-form-item>
                </el-col>


                <el-col :xs="24" :sm="12" :md="8" :lg="8">
                    <el-form-item label="支付方式:" style="width: 100%">
                        <el-select v-model="form.payment_method_id" clearable style="width: 100%">
                            <el-option :value="1" label="支付宝" />
                            <el-option :value="2" label="微信" />
                            <el-option :value="3" label="云闪付" />
                        </el-select>
                    </el-form-item>
                </el-col>
                <el-col :xs="24" :sm="12" :md="8" :lg="8">
                    <el-form-item label="开始时间:" style="width: 100%">
                        <el-date-picker v-model="form.datetime[0]" type="datetime" value-format="YYYY-MM-DD HH:mm:ss"
                            :clearable="false" style="width: 100%" />
                    </el-form-item>
                </el-col>
                <el-col :xs="24" :sm="12" :md="8" :lg="8">
                    <!-- <el-form-item label="创建时间:">
                    <el-date-picker v-model="form.datetime" type="datetimerange" range-separator="至"
                        value-format="YYYY-MM-DD HH:mm:ss" />
                </el-form-item> -->
                    <el-form-item label="结束时间:" style="width: 100%">
                        <el-date-picker v-model="form.datetime[1]" type="datetime" value-format="YYYY-MM-DD HH:mm:ss"
                            :clearable="false" style="width: 100%" />
                    </el-form-item>
                    <text v-if="form.datetime[1] < form.datetime[0]"
                        style="color: red; font-size: 13px;">*结束时间必须大于开始时间</text>
                </el-col>
                <el-col :xs="24" :sm="12" :md="8" :lg="8">
                    <el-form-item style="width: 100%">
                        <el-button type="primary" id="search" @click="search">
                            <el-icon>
                                <Search />
                            </el-icon>
                            搜索
                        </el-button>
                        <el-button type="success" link :icon="Document" @click="hanldeExportExcel">导出Excel</el-button>
                    </el-form-item>
                </el-col>
            </el-row>
        </el-form>
    </div>
    <div class="table-box">
        <div class="table-head">
            <div class="panel">
                <el-row :gutter="10">
                    <el-col :span="4">
                        <el-card shadow="hover" class="card">
                            <template #header>
                                <div class="card-header">
                                    <span>订单笔数</span>
                                </div>
                            </template>
                            <div class="card-body">
                                <div>{{ orderStatistics.pay_number > 0 ? orderStatistics.pay_number : 0 }} </div>
                            </div>
                        </el-card>
                    </el-col>
                    <el-col :span="4">
                        <el-card shadow="hover" class="card">
                            <template #header>
                                <div class="card-header">
                                    <span>应收金额</span>
                                </div>
                            </template>
                            <div class="card-body">
                                <!-- 应收金额= -->
                                <div>{{ orderStatistics.pay_amount > 0 ? orderStatistics.pay_amount : 0 }} </div>
                            </div>
                        </el-card>
                    </el-col>
                    <el-col :span="4">
                        <el-card shadow="hover" class="card">
                            <template #header>
                                <div class="card-header">
                                    <span>实收金额</span>
                                </div>
                            </template>
                            <div class="card-body">
                                <!-- 请求返回的pay_amount字段是订单金额，实收金额= 订单金额 - 退款金额 -->
                                <div>{{ orderStatistics.pay_amount - orderStatistics.refund_amount > 0 ?
                                    parseFloat(orderStatistics.pay_amount - orderStatistics.refund_amount).toFixed(2) :
                                    0 }}
                                </div>
                            </div>
                        </el-card>
                    </el-col>
                    <el-col :span="4">
                        <el-card shadow="hover" class="card">
                            <template #header>
                                <div class="card-header">
                                    <span>退款金额</span>
                                </div>
                            </template>
                            <div class="card-body">
                                <div>{{ orderStatistics.refund_amount > 0 ? orderStatistics.refund_amount : 0 }} </div>
                            </div>
                        </el-card>
                    </el-col>
                    <el-col :span="4">
                        <el-card shadow="hover" class="card">
                            <template #header>
                                <div class="card-header">
                                    <span>手续费</span>
                                </div>
                            </template>
                            <div class="card-body">
                                <div>{{ orderStatistics.fee_amount > 0 ? orderStatistics.fee_amount : 0 }} </div>
                            </div>
                        </el-card>
                    </el-col>
                    <el-col :span="4">
                        <el-card shadow="hover" class="card">
                            <template #header>
                                <div class="card-header">
                                    <span>结算金额</span>
                                </div>
                            </template>
                            <div class="card-body">
                                <div>{{ orderStatistics.pay_amount - orderStatistics.refund_amount -
                                    orderStatistics.fee_amount > 0 ? parseFloat(orderStatistics.pay_amount -
                                        orderStatistics.refund_amount - orderStatistics.fee_amount).toFixed(2) : 0 }} </div>
                            </div>
                        </el-card>
                    </el-col>
                </el-row>
            </div>
        </div>
        <div class="table-main">
            <c-table ref="orderTable" :tableDataUrl="tableDataUrl" :border="true" :isLoadData="false"
                @selectionRows="handleSelectionChange" :row-style="isRed">
                <el-table-column type="selection" width="55" />
                <el-table-column prop="merchant_no" label="商户号" min-width="120" />
                <el-table-column prop="merchant_name" label="商户名称" show-overflow-tooltip min-width="180" />
                <el-table-column prop="store_name" label="门店名称" show-overflow-tooltip min-width="180" />
                <el-table-column label="创建时间" min-width="180" show-overflow-tooltip>
                    <template #default="scope">
                        {{ timeFormat(scope.row.created_at, 'datetime') }}
                    </template>
                </el-table-column>
                <el-table-column prop="trade_no" label="交易流水号" min-width="200">
                    <template #default="scope">
                        <div class="icon-box">
                            <el-tooltip effect="dark" :content="scope.row.trade_no" placement="top-start">
                                <span class="text-overflow">{{ scope.row.trade_no }}</span>
                            </el-tooltip>
                            <span class="copy icon">
                                <el-icon @click="copyWord(scope.row.trade_no)">
                                    <CopyDocument />
                                </el-icon>
                            </span>
                        </div>
                    </template>
                </el-table-column>
                <el-table-column prop="out_trade_no" label="商户订单号" min-width="140">
                    <template #default="scope">
                        <div class="icon-box">
                            <el-tooltip effect="dark" :content="scope.row.out_trade_no" placement="top-start">
                                <span class="text-overflow">{{ scope.row.out_trade_no }}</span>
                            </el-tooltip>
                            <span class="copy icon">
                                <el-icon @click="copyWord(scope.row.out_trade_no)">
                                    <CopyDocument />
                                </el-icon>
                            </span>
                        </div>
                    </template>
                </el-table-column>
                <el-table-column prop="status" label="订单状态" min-width="120">
                    <template #default="scope">
                        <span v-if="scope.row.status == 0">
                            <el-tag type="info">取消支付</el-tag>
                        </span>
                        <span v-if="scope.row.status == 1">
                            <el-tag type="info">用户支付中</el-tag>
                        </span>
                        <span v-if="scope.row.status == 2">
                            <el-tag type="info">已关闭</el-tag>
                        </span>
                        <span v-if="scope.row.status == 3">
                            <el-tag type="success">支付成功</el-tag>
                        </span>
                        <span v-if="scope.row.status == 4">
                            <el-tag type="danger">支付失败</el-tag>
                        </span>
                        <span v-if="scope.row.status == 5">
                            <el-tag type="warning">部分退款</el-tag>
                        </span>
                        <span v-if="scope.row.status == 6">
                            <el-tag type="info">已退款</el-tag>
                        </span>
                        <span v-if="scope.row.status == 7">
                            <el-tag type="info">已撤销</el-tag>
                        </span>
                        <span v-if="scope.row.status == 8">
                            <el-tag type="info">已接收</el-tag>
                        </span>
                    </template>
                </el-table-column>
                <el-table-column prop="pay_amount" label="支付金额(元)" min-width="120" />
                <el-table-column prop="total_amount" label="订单金额(元)" min-width="120" />
                <el-table-column prop="fee_amount" label="手续费(元)" min-width="120" />
                <el-table-column prop="refund_amount" label="退款金额(元)" min-width="120" />
                <!-- <el-table-column prop="is_settlement" label="是否结算">
                    <template #default="scope">
                        <span v-if="scope.row.is_settlement == 1">
                            已结算
                        </span>
                        <span v-else>
                            未结算
                        </span>
                    </template>
                </el-table-column> -->
                <!-- <el-table-column prop="agent_name" label="代理商名称" /> -->
                <el-table-column label="支付渠道" min-width="120">
                    <template #default="scope">
                        {{ scope.row.payment_channel ? scope.row.payment_channel : '暂无' }}
                    </template>
                </el-table-column>
                <el-table-column prop="payment_method" label="支付方式" min-width="120" />
                <el-table-column label="操作" width="80" fixed="right">
                    <template #default="scope">
                        <el-link type="primary" @click="details(scope.row)">详情</el-link>
                    </template>
                </el-table-column>
            </c-table>
        </div>
    </div>
    <el-dialog v-model="isShowDetail" :show-close="false" width="80%" top="5vh" @close="closeOrderDetail">
        <template #header>
            <span style="font-size:20px;color:#000">订单详情</span>
        </template>
        <el-skeleton v-if="skeleton" :rows="9" :animated='true' :loading='true' />
        <div v-else class="details">
            <el-row justify="space-evenly" :gutter="25" class="row-bg"
                v-if="orderDetail.status != 3 && orderDetail.status != 5 && orderDetail.status != 6">
                <el-col :span="24" class="col-bg">
                    <el-button type="primary" @click="onRefreshOrderStatus" :icon="Refresh" size="medium">
                        手动检测
                    </el-button>
                </el-col>
            </el-row>
            <el-row justify="space-evenly" :gutter="25" class="row-bg">
                <el-col :span="4" class="col-bg">交易流水号：</el-col>
                <el-col :span="4" class="col-bg">
                    <el-tooltip :content="orderDetail.trade_no" placement="top">
                        {{ orderDetail.trade_no }}
                    </el-tooltip>
                </el-col>
                <el-col :span="4" class="col-bg">商户订单号：</el-col>
                <el-col :span="4" class="col-bg">
                    <el-tooltip :content="orderDetail.out_trade_no" placement="top">
                        {{ orderDetail.out_trade_no }}
                    </el-tooltip>
                </el-col>
                <el-col :span="4" class="col-bg">渠道订单号：</el-col>
                <el-col :span="4" class="col-bg">
                    <el-tooltip :content="orderDetail.channel_trade_no" placement="top">
                        {{ orderDetail.channel_trade_no }}
                    </el-tooltip>
                </el-col>
            </el-row>
            <el-row justify="space-evenly" :gutter="25" class="row-bg">
                <el-col :span="4" class="col-bg">代理商名称：</el-col>
                <el-col :span="4" class="col-bg">{{ orderDetail.agent_name }}</el-col>
                <el-col :span="4" class="col-bg">商户名称：</el-col>
                <el-col :span="4" class="col-bg">{{ orderDetail.merchant_name }}</el-col>
                <el-col :span="4" class="col-bg">商户编号：</el-col>
                <el-col :span="4" class="col-bg">{{ orderDetail.merchant_no }}</el-col>
            </el-row>
            <el-row justify="space-evenly" :gutter="25" class="row-bg">
                <el-col :span="4" class="col-bg">店铺名称：</el-col>
                <el-col :span="4" class="col-bg">{{ orderDetail.store_name }}</el-col>
                <el-col :span="4" class="col-bg">业务员：</el-col>
                <el-col :span="4" class="col-bg">{{ orderDetail.salesman_name }}</el-col>
                <el-col :span="4" class="col-bg">支付设备：</el-col>
                <el-col :span="4" class="col-bg">{{ orderDetail.serial_number }}</el-col>
            </el-row>
            <el-row justify="space-evenly" :gutter="25" class="row-bg">
                <el-col :span="4" class="col-bg">支付渠道：</el-col>
                <el-col :span="4" class="col-bg">{{ orderDetail.payment_channel }}</el-col>
                <el-col :span="4" class="col-bg">支付方式：</el-col>
                <el-col :span="4" class="col-bg">{{ orderDetail.payment_method }}</el-col>
                <el-col :span="4" class="col-bg">订单状态：</el-col>
                <el-col :span="4" class="col-bg">
                    <span v-if="orderDetail.status == 0">
                        <el-tag type="info">取消支付</el-tag>
                    </span>
                    <span v-if="orderDetail.status == 1">
                        <el-tag type="info">用户支付中</el-tag>
                    </span>
                    <span v-if="orderDetail.status == 2">
                        <el-tag type="info">已关闭</el-tag>
                    </span>
                    <span v-if="orderDetail.status == 3">
                        <el-tag type="success">支付成功</el-tag>
                    </span>
                    <span v-if="orderDetail.status == 4">
                        <el-tag type="danger">支付失败</el-tag>
                    </span>
                    <span v-if="orderDetail.status == 5">
                        <el-tag type="warning">部分退款</el-tag>
                    </span>
                    <span v-if="orderDetail.status == 6">
                        <el-tag type="info">已退款</el-tag>
                    </span>
                    <span v-if="orderDetail.status == 7">
                        <el-tag type="info">已撤销</el-tag>
                    </span>
                    <span v-if="orderDetail.status == 8">
                        <el-tag type="info">已接收</el-tag>
                    </span>
                </el-col>
            </el-row>
            <el-row justify="space-evenly" :gutter="25" class="row-bg">
                <el-col :span="4" class="col-bg border-bottom">支付金额：</el-col>
                <el-col :span="4" class="col-bg border-bottom">{{ orderDetail.pay_amount }}
                </el-col>
                <el-col :span="4" class="col-bg border-bottom">交易费率：</el-col>
                <el-col :span="4" class="col-bg border-bottom">{{ orderDetail.rate }}</el-col>
                <el-col :span="4" class="col-bg border-bottom">手续费：</el-col>
                <el-col :span="4" class="col-bg border-bottom">{{ orderDetail.fee_amount }}</el-col>
            </el-row>
            <el-row justify="space-evenly" :gutter="25" class="row-bg">
                <el-col :span="4" class="col-bg">优惠金额：</el-col>
                <el-col :span="4" class="col-bg">{{ orderDetail.discount_amount }}</el-col>
                <el-col :span="4" class="col-bg">商户实收金额：</el-col>
                <el-col :span="4" class="col-bg">{{ orderDetail.receipt_amount }}</el-col>
                <el-col :span="4" class="col-bg">退款金额：</el-col>
                <el-col :span="4" class="col-bg">{{ orderDetail.refund_amount }}</el-col>
            </el-row>
            <el-row justify="space-evenly" :gutter="25" class="row-bg">
                <el-col :span="4" class="col-bg">创建时间：</el-col>
                <el-col :span="4" class="col-bg">{{ timeFormat(orderDetail.created_at, 'datetime') }}</el-col>
                <el-col :span="4" class="col-bg">备注：</el-col>
                <el-col :span="12" class="col-bg">{{ orderDetail.remarks }}</el-col>
            </el-row>
            <!-- <el-row justify="space-evenly" :gutter="25" class="row-bg" style="height:100px">
                <el-col :span="4" class="col-bg" style="line-height:100px">一级代理商信息：</el-col>
                <el-col :span="20" class="col-bg">
                    <el-row justify="space-evenly" :gutter="25">
                        <el-col :span="6" class="col-bg" style="background-color:#FAFAFA;">一级代理商分润</el-col>
                        <el-col :span="6" class="col-bg" style="background-color:#FAFAFA;">一级应收下级分润</el-col>
                        <el-col :span="6" class="col-bg" style="background-color:#FAFAFA;">一级上级应收分润</el-col>
                        <el-col :span="6" class="col-bg" style="background-color:#FAFAFA;">一级最终收益</el-col>
                    </el-row>
                    <el-row justify="space-evenly" :gutter="25">
                        <el-col :span="6" class="col-bg" style="background-color:#fff;">{{
                            orderDetail.first_agent_profit
                            }}</el-col>
                        <el-col :span="6" class="col-bg" style="background-color:#fff;">{{
                            orderDetail.first_receivable_profit
                            }}</el-col>
                        <el-col :span="6" class="col-bg" style="background-color:#fff;">{{
                            orderDetail.first_superior_receivable_profit
                            }}</el-col>
                        <el-col :span="6" class="col-bg" style="background-color:#fff;">{{
                            orderDetail.first_last_profit
                            }}</el-col>
                    </el-row>
                </el-col>
            </el-row>
            <el-row justify="space-evenly" :gutter="25" class="row-bg" style="height:100px">
                <el-col :span="4" class="col-bg" style="line-height:100px">二级代理商信息：</el-col>
                <el-col :span="20">
                    <el-row justify="space-evenly" :gutter="25">
                        <el-col :span="6" class="col-bg" style="background-color:#FAFAFA;">二级代理商分润</el-col>
                        <el-col :span="6" class="col-bg" style="background-color:#FAFAFA;">二级应收下级分润</el-col>
                        <el-col :span="6" class="col-bg" style="background-color:#FAFAFA;">二级上级应收分润</el-col>
                        <el-col :span="6" class="col-bg" style="background-color:#FAFAFA;">二级最终收益</el-col>
                    </el-row>
                    <el-row justify="space-evenly" :gutter="25">
                        <el-col :span="6" class="col-bg" style="background-color:#fff;">{{
                            orderDetail.second_agent_profit
                            }}</el-col>
                        <el-col :span="6" class="col-bg" style="background-color:#fff;">{{
                            orderDetail.second_receivable_profit
                            }}</el-col>
                        <el-col :span="6" class="col-bg" style="background-color:#fff;">{{
                            orderDetail.second_superior_receivable_profit
                            }}</el-col>
                        <el-col :span="6" class="col-bg" style="background-color:#fff;">{{
                            orderDetail.second_last_profit
                            }}</el-col>
                    </el-row>
                </el-col>
            </el-row>
            <el-row justify="space-evenly" :gutter="25" class="row-bg" style="height:100px">
                <el-col :span="4" class="col-bg" style="line-height:100px">三级代理商信息：</el-col>
                <el-col :span="20">
                    <el-row justify="space-evenly" :gutter="25">
                        <el-col :span="6" class="col-bg" style="background-color:#FAFAFA;">
                            三级代理商分润</el-col>
                        <el-col :span="6" class="col-bg" style="background-color:#FAFAFA;">三级应收下级分润</el-col>
                        <el-col :span="6" class="col-bg" style="background-color:#FAFAFA;">三级上级应收分润</el-col>
                        <el-col :span="6" class="col-bg" style="background-color:#FAFAFA;">三级最终收益</el-col>
                    </el-row>
                    <el-row justify="space-evenly" :gutter="25">
                        <el-col :span="6" class="col-bg" style="background-color:#fff;">{{
                            orderDetail.three_agent_profit
                            }}</el-col>
                        <el-col :span="6" class="col-bg" style="background-color:#fff;">{{
                            orderDetail.three_receivable_profit
                            }}</el-col>
                        <el-col :span="6" class="col-bg" style="background-color:#fff;">{{
                            orderDetail.three_superior_receivable_profit
                            }}</el-col>
                        <el-col :span="6" class="col-bg" style="background-color:#fff;">{{
                            orderDetail.three_last_profit
                            }}</el-col>
                    </el-row>
                </el-col>
            </el-row> -->
        </div>
        <template #footer>
            <el-button @click="close">关闭</el-button>
        </template>
    </el-dialog>
</template>

<script setup>
import { getCurrentInstance, ref, onMounted } from 'vue'
import { ElMessage } from 'element-plus'
import cTable from '@/components/CustomTable'
import { Search, CopyDocument, Refresh } from '@element-plus/icons-vue'
import { timeFormat, getTodayStartAndEnds } from '@/func/time'

const orderTable = ref(null)
const isShowDetail = ref(false)
const orderDetail = ref(null)
const skeleton = ref(true)
const { proxy } = getCurrentInstance() // 当前实例


const tableDataUrl = proxy.$api.order.list

let form = ref({
    status: 'default',
    datetime: getTodayStartAndEnds()
})
const search = () => {
    checkTime()
    queryOrderCollect()
    orderTable.value.setCustomLoad(true)
    orderTable.value.search(form)
}

onMounted(() => {
    console.log(form.value.datetime[0], form.value.datetime[1]);
    // checkTime()
    // queryOrderCollect()
    // orderTable.value.setCustomLoad(true)
    // orderTable.value.search(form)
    getpaymentChannels()
    setTimeout(() => search(), 300)
})

const orderStatistics = ref({})
const queryOrderCollect = () => {
    proxy.$api.order.statistics.merchantOrderDetailCollect(form.value).then(r => {
        orderStatistics.value = r.data
    })
}


const checkTime = () => {
    if (form.value.datetime.length == 2) {
        form.value.time_start = form.value.datetime[0]
        form.value.time_end = form.value.datetime[1]
    }
}

const details = (row) => {
    isShowDetail.value = true
    proxy.$api.order.details({ id: row.id, created_at: timeFormat(row.created_at, 'date') }).then(r => {
        if (r.status == 200) {
            orderDetail.value = r.data
            skeleton.value = false
        }
    })
}

const onRefreshOrderStatus = () => {
    proxy.$api.order.syncStatus({
        id: orderDetail.value.id,
        created_at: timeFormat(orderDetail.value.created_at, 'date')
    }).then(r => {
        if (r.status == 200) {
            ElMessage({ message: '订单状态已重新同步', type: 'success' })
            details(orderDetail.value)
        }
    })
}

const close = () => {
    isShowDetail.value = false
    skeleton.value = true
    orderDetail.value = null
}

const closeOrderDetail = () => {
    skeleton.value = true
}

const copyWord = (word) => {
    let tag = document.createElement('textarea');
    tag.setAttribute('id', 'cp_hgz_input');
    tag.value = word;
    document.getElementsByTagName('body')[0].appendChild(tag);
    document.getElementById('cp_hgz_input').select();
    document.execCommand('copy');
    document.getElementById('cp_hgz_input').remove();

    ElMessage({ message: '复制成功', type: 'success' })
}

const channelList = ref({})
const getpaymentChannels = () => {
    proxy.$api.order.statistics.paymentChannels().then(r => {
        channelList.value = r.data
    })
}

const hanldeExportExcel = () => {
    form.value.downloadFile = true
    const filename = '交易订单-' + timeFormat('', 'string')
    // console.log(proxy.$api.order.statistics);
    proxy.$api.order.statisticsExport(form.value, filename)
}

const selectedArrData = ref([]);
// 处理选中行数据的方法
const handleSelectionChange = (rows) => {
    selectedArrData.value = rows; // 存储选中的行数据
};

// 设置选中行的样式
const isRed = ({ row }) => {
    const checkIdList = selectedArrData.value.map((item) => item.id);
    if (checkIdList.includes(row.id)) {
        return {
            backgroundColor: "#cde8f8"
        };
    }
};

</script>

<style lang="less" scoped>
@import './details.less';

.form-box {
    margin-bottom: 10px;
}

.icon-box {
    display: flex;
    flex-direction: row;

    .text-overflow {
        width: 90%;
        overflow: hidden;
        white-space: nowrap !important;
        text-overflow: ellipsis !important;
    }

    .copy {
        color: #409EFF !important;
        cursor: pointer;
    }

    .copyed {
        color: #52c41a !important;
    }

    .icon {
        font-size: 14px !important;

    }
}

.panel {
    align-items: center;

    .card {
        .card-header {
            font-size: 16px;
        }

        .card-body {
            font-size: 18px;
            color: var(--el-color-primary);

            div::before {
                content: "¥";
                /* 人民币符号 */
                display: inline-block;
                margin-right: 5px;
                /* 可选：如果您想在符号和文本之间添加一些空隙 */
            }
        }

        ::v-deep .el-card__body {
            padding: 10px;
        }

        ::v-deep .el-card__header {
            padding: 10px;
        }
    }
}
</style>
