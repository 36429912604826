<template>
    <div>
        <el-dialog v-model="isShow" width="80%" :draggable="true" :destroy-on-close="true">
            <template #title>
                <span>{{ merchantName }} - 优惠活动管理</span>
            </template>
            <div>
                <el-radio-group v-model="activity" size="large" @change="change">
                    <el-radio-button label="activity_list">活动列表</el-radio-button>
                    <el-radio-button label="requested_activity">已申请活动</el-radio-button>
                    <el-radio-button label="activity_detail">活动补贴明细</el-radio-button>
                </el-radio-group>

                <div v-if="showTable == 'activity_list'">
                    <el-table :data="tableData" style="width: 100%" v-loading="isloading">
                        <el-table-column fixed="left" prop="polName" label="活动名称" width="260" show-overflow-tooltip
                            :align="'center'" />
                        <el-table-column prop="actState" label="活动状态" width="120" :align="'center'">
                            <template #default="scope">
                                <el-tag v-if="scope.row.actState == '1'" type="success">生效中</el-tag>
                                <el-tag v-else type="info">已过期</el-tag>
                            </template>
                        </el-table-column>
                        <el-table-column prop="polId" label="活动编号" width="140" :align="'center'" />
                        <el-table-column prop="areaCodes" label="活动参与范围" width="120" show-overflow-tooltip
                            :align="'center'">
                            <template #default="scope">
                                <text v-if="scope.row.areaCodes == '0'">全国</text>
                                <text v-else> {{ getAreaNames(scope.row.areaCodes) }}</text>
                            </template>
                        </el-table-column>
                        <el-table-column prop="ageName" label="落地银行名称" width="120" :align="'center'" />
                        <el-table-column prop="fallAgetId" label="落地银行编号" width="160" show-overflow-tooltip
                            :align="'center'" />
                        <el-table-column prop="actStartTime" label="活动开始时间" width="120" :align="'center'">
                            <template #default="scope">
                                {{ scope.row.actStartTime.slice(0, 4) + '-' + scope.row.actStartTime.slice(4, 6) + '-' +
                                    scope.row.actStartTime.slice(6, 8) }}
                            </template>
                        </el-table-column>
                        <el-table-column prop="actEndTime" label="活动结束时间" width="120" :align="'center'">
                            <template #default="scope">
                                {{ scope.row.actEndTime.slice(0, 4) + '-' + scope.row.actEndTime.slice(4, 6) + '-' +
                                    scope.row.actEndTime.slice(6, 8) }}
                            </template>
                        </el-table-column>
                        <el-table-column prop="actRule" label="活动规则" width="240" show-overflow-tooltip
                            :align="'center'" />

                        <el-table-column prop="isXwRegister" label="小微商户报名" width="120" :align="'center'">
                            <template #default="scope">
                                <el-tag v-if="scope.row.isXwRegister == '1'" type="primary">允许</el-tag>
                                <el-tag v-else type="danger">禁止</el-tag>
                            </template>
                        </el-table-column>
                        <el-table-column prop="isJskSign" label="重复结算卡报名" width="130" :align="'center'">
                            <template #default="scope">
                                <el-tag v-if="scope.row.isJskSign == '1'" type="primary">允许</el-tag>
                                <el-tag v-else type="danger">禁止</el-tag>
                            </template>
                        </el-table-column>
                        <el-table-column prop="subsidyEffectPref" label="补贴生效标识" width="120" :align="'center'">
                            <template #default="scope">
                                <el-tag v-if="scope.row.subsidyEffectPref == '1'" type="warning">入网实时生效</el-tag>
                                <el-tag v-else type="warning">提报生效</el-tag>
                            </template>
                        </el-table-column>
                        <el-table-column prop="cusAuthLimit" label="商户入网准入限制" width="200" :align="'center'">
                            <template #default="scope">
                                <el-tag v-if="scope.row.cusAuthLimit != null" type="warning">允许进件后的前{{
                                    scope.row.cusAuthLimit
                                    }}个月报名</el-tag>
                            </template>
                        </el-table-column>
                        <el-table-column fixed="right" label="操作" min-width="120" :align="'center'">
                            <template #default="scope">
                                <el-button link type="primary" size="small"
                                    @click="openSubmitPoup(scope.row)">前往报名</el-button>
                            </template>
                        </el-table-column>
                    </el-table>


                    <div style="display: flex; justify-content: flex-end; margin-top: 20px; margin-right: 20px;">
                        <el-pagination v-model:currentPage="currentPage" v-model:pageSize="pageSize" :background="true"
                            layout="prev, next, jumper, slot" :total="totalItems" @current-change="handlePageChange"
                            @size-change="handleSizeChange">
                            <el-icon @click="refresh" style="cursor:pointer">
                                <Refresh />
                            </el-icon>
                        </el-pagination>
                    </div>
                </div>

                <div v-if="showTable == 'requested_activity'">
                    <el-table :data="actTableData" style="width: 100%" v-loading="isloading">
                        <el-table-column fixed="left" prop="polName" label="活动名称	" width="260" :align="'center'"
                            show-overflow-tooltip />
                        <el-table-column prop="busName" label="商户名称" width="200" :align="'center'" />
                        <el-table-column prop="regisStatus" label="报名状态" width="120" :align="'center'">
                            <template #default="scope">
                                <el-tag v-if="scope.row.regisStatus == 0" type="info">未报名</el-tag>
                                <el-tag v-if="scope.row.regisStatus == 1" type="warning">报名中</el-tag>
                                <el-tag v-if="scope.row.regisStatus == 2" type="success">报名成功</el-tag>
                                <el-tag v-if="scope.row.regisStatus == 3" type="danger">报名失败</el-tag>
                            </template>
                        </el-table-column>
                        <el-table-column prop="subId" label="活动编号" width="140" />
                        <el-table-column prop="custId" label="商户号" width="120" :align="'center'" />
                        <el-table-column prop="ageName" label="落地银行名称" width="120" :align="'center'" />
                        <el-table-column prop="fallAgetId" label="落地银行编号" width="160" :align="'center'" />
                        <el-table-column prop="applyWay" label="报名方式" width="120" :align="'center'">
                            <template #default="scope">
                                <el-tag v-if="scope.row.applyWay == 0" type="primary">星驿秘书APP报名</el-tag>
                                <el-tag v-if="scope.row.applyWay == 1" type="primary">国通后台</el-tag>
                                <el-tag v-if="scope.row.applyWay == 2" type="primary">后台补贴配置新增</el-tag>
                                <el-tag v-if="scope.row.applyWay == 3" type="primary">机构平台批量报名</el-tag>
                                <el-tag v-if="scope.row.applyWay == 4" type="primary">服务化ISV</el-tag>
                            </template>
                        </el-table-column>
                        <el-table-column prop="cardStatus" label="开卡状态" width="120" :align="'center'">
                            <template #default="scope">
                                <el-tag v-if="scope.row.cardStatus == 0" type="info">已申请</el-tag>
                                <el-tag v-if="scope.row.cardStatus == 1" type="info">已预约</el-tag>
                                <el-tag v-if="scope.row.cardStatus == 2" type="success">已开卡</el-tag>
                                <el-tag v-if="scope.row.cardStatus == 3" type="danger">开卡失败</el-tag>
                                <el-tag v-if="scope.row.cardStatus == 4" type="success">已绑定</el-tag>
                            </template>
                        </el-table-column>
                        <el-table-column prop="auditStatus" label="审核状态" width="120" :align="'center'">
                            <template #default="scope">
                                <el-tag v-if="scope.row.auditStatus == 0" type="info">未审核</el-tag>
                                <el-tag v-if="scope.row.auditStatus == 1" type="success">已审核</el-tag>
                            </template>
                        </el-table-column>
                        <el-table-column prop="sendStatus" label="发送状态" width="120" :align="'center'">
                            <template #default="scope">
                                <el-tag v-if="scope.row.sendStatus == 0" type="info">未发送</el-tag>
                                <el-tag v-if="scope.row.sendStatus == 1" type="success">已发送</el-tag>
                            </template>
                        </el-table-column>
                        <el-table-column prop="regisTime" label="报名时间" width="120" :align="'center'">
                            <template #default="scope">
                                {{ scope.row.regisTime.slice(0, 4) + '-' + scope.row.regisTime.slice(4, 6) + '-' +
                                    scope.row.regisTime.slice(6, 8) }}
                            </template>
                        </el-table-column>
                        <el-table-column prop="auditTime" label="审核时间" width="120" :align="'center'">
                            <template #default="scope">
                                {{ scope.row.auditTime.slice(0, 4) + '-' + scope.row.auditTime.slice(4, 6) + '-' +
                                    scope.row.auditTime.slice(6, 8) }}
                            </template>
                        </el-table-column>
                        <el-table-column prop="sendTime" label="发送时间" width="120" :align="'center'">
                            <template #default="scope">
                                {{ scope.row.sendTime.slice(0, 4) + '-' + scope.row.sendTime.slice(4, 6) + '-' +
                                    scope.row.sendTime.slice(6, 8) }}
                            </template>
                        </el-table-column>
                        <el-table-column prop="confTime" label="配置时间" width="120" :align="'center'">
                            <template #default="scope">
                                {{ scope.row.confTime.slice(0, 4) + '-' + scope.row.confTime.slice(4, 6) + '-' +
                                    scope.row.confTime.slice(6, 8) }}
                            </template>
                        </el-table-column>
                        <el-table-column prop="failReason" label="失败原因" width="120" :align="'center'"
                            show-overflow-tooltip fixed="right" />
                        <!-- <el-table-column fixed="right" label="操作" min-width="120" :align="'center'">
                            <template #default="scope">
                                <el-button link type="primary" size="small"
                                    @click="merchantSubList(scope.row)">补贴明细</el-button>
                            </template>
                        </el-table-column> -->
                    </el-table>
                </div>

                <div v-if="showTable == 'activity_detail'" style="padding: 40px;">
                    <div v-if="activityDetail != null" v-loading="isloading" element-loading-text="加载中...">
                        <el-card v-for="(item, index) in activityDetail" :key="index" style="margin-top: 20px;">
                            <template #header>
                                <div class="card-header">
                                    <span>{{ item.custName }} - {{ item.custId }}</span>
                                </div>
                            </template>
                            <div class="card-body">
                                <el-row :gutter="20">
                                    <el-col :span="12">
                                        <p><strong>状态:</strong>
                                            <el-tag v-if="getStatus(item.status) == '生效'" type="success">生效</el-tag>
                                            <el-tag v-else type="danger">失效</el-tag>
                                        </p>
                                        <p><strong>补贴机构编号:</strong> {{ item.btagetid }}</p>
                                        <p><strong>补贴编号:</strong> {{ item.btId }}</p>
                                        <p><strong>手续费总额度 :</strong> {{ item.totalSubsidy }}</p>
                                        <p><strong>手续费月额度:</strong> {{ item.monthTotalSubidy }}</p>
                                        <p><strong>交易额总额度:</strong> {{ item.txamtTotalSubsidy }}</p>
                                        <p><strong>交易额月额度:</strong> {{ item.txamtMonthSubsidy }}</p>
                                        <p><strong>活动生效日期:</strong> {{ item.beginDate }}</p>
                                    </el-col>
                                    <el-col :span="12">
                                        <p><strong>补贴商户类型:</strong> {{ getCustomerType(item.custType) }}</p>
                                        <p><strong>补贴机构名称:</strong> {{ item.btagetname }}</p>
                                        <p><strong>补贴类型:</strong> {{ getDiscountType(item.discountFlag) }}</p>
                                        <p><strong>手续费总剩余额度:</strong> {{ item.surplusTotalSubsidy }}</p>
                                        <p><strong>手续费月剩余额度:</strong> {{ item.remainMonthTotalSubidy }}</p>
                                        <p><strong>交易额总剩余额度:</strong> {{ item.subTotalTxamtResidue }}</p>
                                        <p><strong>交易额月剩余额度:</strong> {{ item.subMonthTxamtResidue }}</p>
                                        <p><strong>活动结束日期:</strong> {{ item.endDate }}</p>
                                    </el-col>
                                </el-row>
                            </div>
                        </el-card>
                    </div>
                    <div v-else v-loading="isloading" element-loading-text="加载中...">
                        <el-empty description="暂无数据" />
                    </div>
                </div>

            </div>
            <activitySubmit ref="submitPoup"></activitySubmit>

        </el-dialog>
    </div>
</template>

<script setup>
import { defineExpose, ref, getCurrentInstance } from 'vue';
import { Refresh } from '@element-plus/icons-vue'
import activitySubmit from '../gtxyActivity/activitySubmit'
import getAreaNames from '@/tools/areaCodes.js'
const { proxy } = getCurrentInstance()

const tableData = ref([])
const activity = ref('activity_list')
const showTable = ref('activity_list')
const currentPage = ref(1)  // 当前页
const pageSize = ref(10)     // 每页条数
const totalItems = ref(9999)    // 总条目数
const isloading = ref(false)

const change = (value) => {
    showTable.value = value

    if (showTable.value == 'activity_list') {
        currentPage.value = 1
        fetchData()
    }

    if (showTable.value == 'requested_activity') {
        merchantSignedupList()
    }

    if (showTable.value == 'activity_detail') {
        merchantSubList()
    }
}

//查询活动
const fetchData = () => {
    isloading.value = true
    proxy.$api.merchant.activity.gtxyActList({
        page: currentPage.value,
        size: pageSize.value
    }).then((r) => {
        tableData.value = r.data.data
        isloading.value = false

    }).catch((error) => {
        console.error("Error fetching data: ", error)
        isloading.value = false
    })
}
const handlePageChange = (newPage) => {
    currentPage.value = newPage
    fetchData()
}

const handleSizeChange = (newSize) => {
    pageSize.value = newSize
    fetchData()
}


const refresh = () => {
    fetchData()
}

const merchantName = ref('')
const merchantId = ref()
const isShow = ref(false)
const open = (merchantInfo, merchant_id) => {
    console.log(merchantInfo, 'merchantInfo');
    isShow.value = true
    merchantName.value = merchantInfo.name
    merchantId.value = merchant_id
    fetchData()  // 打开弹窗时请求数据
}



//活动报名
const submitPoup = ref(null)
const openSubmitPoup = (activeInfo) => {
    submitPoup.value.open(activeInfo, merchantId.value)
}


//商家已报名活动查询

// const actTableData = ref([{
//     busName: "商户A",
//     polName: "活动A",
//     subId: "A001",
//     custId: "C001",
//     ageName: "银行A",
//     fallAgetId: "BA001",
//     applyWay: 0,  // 星驿秘书APP报名
//     cardStatus: 0,
//     regisStatus: 0,
//     auditStatus: 0,
//     sendStatus: 0,
//     regisTime: "2024-01-01 10:00",
//     auditTime: "2024-01-02 14:00",
//     sendTime: "2024-01-03 09:00",
//     confTime: "2024-01-04 16:00",
//     failReason: "-",
// },
// {
//     busName: "商户B",
//     polName: "活动B",
//     subId: "B001",
//     custId: "C002",
//     ageName: "银行B",
//     fallAgetId: "BB001",
//     applyWay: 1,  // 国通后台
//     cardStatus: 1,
//     regisStatus: 1,
//     auditStatus: 1,
//     sendStatus: 1,
//     regisTime: "2024-02-01 11:00",
//     auditTime: "2024-02-02 15:00",
//     sendTime: "2024-02-03 10:00",
//     confTime: "2024-02-04 17:00",
//     failReason: "信息不全",
// },
// {
//     busName: "商户C",
//     polName: "活动C",
//     subId: "C001",
//     custId: "C003",
//     ageName: "银行C",
//     fallAgetId: "BC001",
//     applyWay: 2,  // 后台补贴配置新增
//     cardStatus: 2,
//     regisStatus: 2,
//     auditStatus: 1,
//     sendStatus: 1,
//     regisTime: "2024-03-01 12:00",
//     auditTime: "2024-03-02 16:00",
//     sendTime: "2024-03-03 11:00",
//     confTime: "2024-03-04 18:00",
//     failReason: "审核未通过",
// },
// {
//     busName: "商户D",
//     polName: "活动D",
//     subId: "D001",
//     custId: "C004",
//     ageName: "银行D",
//     fallAgetId: "BD001",
//     applyWay: 3,  // 机构平台批量报名
//     cardStatus: 3,
//     regisStatus: 3,
//     auditStatus: 0,
//     sendStatus: 0,
//     regisTime: "2024-04-01 13:00",
//     auditTime: "2024-04-02 17:00",
//     sendTime: "2024-04-03 12:00",
//     confTime: "2024-04-04 19:00",
//     failReason: "-",
// },
// {
//     busName: "商户E",
//     polName: "活动E",
//     subId: "E001",
//     custId: "C005",
//     ageName: "银行E",
//     fallAgetId: "BE001",
//     applyWay: 4,  // 服务化ISV
//     cardStatus: 4,
//     regisStatus: 3,
//     auditStatus: 1,
//     sendStatus: 1,
//     regisTime: "2024-05-01 14:00",
//     auditTime: "2024-05-02 18:00",
//     sendTime: "2024-05-03 13:00",
//     confTime: "2024-05-04 20:00",
//     failReason: "资料未完善",
// },])
const actTableData = ref([])
const merchantSignedupList = () => {
    isloading.value = true
    proxy.$api.merchant.activity.merchantSignedupList({
        page: 1,
        size: 10,
        merchant_id: merchantId.value
    }).then((r) => {
        if (r.data.code == '000000') {
            console.log('暂无数据');
        } else {
            actTableData.value = r.data.data
        }
        isloading.value = false
    }).catch((error) => {
        console.error("Error fetching data: ", error)
        isloading.value = false
    })
}


/**用于查询已报名成功的商户，生成的补贴活动明细及额度*/
// const activityDetail = ref([
//     {
//         custId: "C123456789",
//         custName: "商户A",
//         btId: "B001",
//         btagetid: "S001",
//         btagetname: "机构A",
//         discountFlag: "1", // 服务商补贴
//         totalSubsidy: "10000",
//         surplusTotalSubsidy: "8000",
//         monthTotalSubidy: "2000",
//         remainMonthTotalSubidy: "1500",
//         txamtTotalSubsidy: "50000",
//         subTotalTxamtResidue: "40000",
//         txamtMonthSubsidy: "10000",
//         subMonthTxamtResidue: "8000",
//         custType: "1", // 普通商户
//         beginDate: "2024-01-01",
//         endDate: "2024-12-31",
//         status: "1" // 生效
//     },
//     {
//         custId: "C987654321",
//         custName: "商户B",
//         btId: "B002",
//         btagetid: "S002",
//         btagetname: "机构B",
//         discountFlag: "2", // 公司补贴
//         totalSubsidy: "5000",
//         surplusTotalSubsidy: "3000",
//         monthTotalSubidy: "1000",
//         remainMonthTotalSubidy: "500",
//         txamtTotalSubsidy: "20000",
//         subTotalTxamtResidue: "15000",
//         txamtMonthSubsidy: "5000",
//         subMonthTxamtResidue: "3000",
//         custType: "2", // 小微商户
//         beginDate: "2024-02-01",
//         endDate: "2024-06-30",
//         status: "0" // 失效
//     },
//     {
//         custId: "C112233445",
//         custName: "商户C",
//         btId: "B003",
//         btagetid: "S003",
//         btagetname: "机构C",
//         discountFlag: "11", // 落地银行补贴
//         totalSubsidy: "20000",
//         surplusTotalSubsidy: "15000",
//         monthTotalSubidy: "3000",
//         remainMonthTotalSubidy: "2500",
//         txamtTotalSubsidy: "100000",
//         subTotalTxamtResidue: "95000",
//         txamtMonthSubsidy: "20000",
//         subMonthTxamtResidue: "18000",
//         custType: "0", // 全部
//         beginDate: "2024-03-01",
//         endDate: "2024-12-31",
//         status: "1" // 生效
//     }
// ]);
const activityDetail = ref([])

const getDiscountType = (discountFlag) => {
    switch (discountFlag) {
        case '1': return '服务商补贴';
        case '2': return '公司补贴';
        case '11': return '落地银行补贴';
        default: return '未知类型';
    }
};

const getCustomerType = (custType) => {
    switch (custType) {
        case '0': return '全部';
        case '1': return '普通商户';
        case '2': return '小微商户';
        default: return '未知类型';
    }
};

const getStatus = (status) => {
    return status === '1' ? '生效' : '失效';
};


const merchantSubList = () => {
    isloading.value = true
    proxy.$api.merchant.activity.merchantSubList({
        page: 1,
        size: 20,
        merchant_id: merchantId.value
    }).then((r) => {
        if (r.data.code == '000000') {
            activityDetail.value = r.data.data
        }
        else {
            activityDetail.value = r.data.data
        }
        isloading.value = false
    }).catch((error) => {
        console.error("Error fetching data: ", error)
        isloading.value = false
    })
}

defineExpose({ open })
</script>

<style lang="less" scoped>
.list-box {
    margin-top: 30px;
}

.text-overflow {
    width: 50%;
    overflow: hidden;
    white-space: nowrap !important;
    text-overflow: ellipsis !important;
}
</style>
