<template>
    <el-drawer v-model="isShow" size="60%" :show-close="false">
        <template #header>
            进件渠道 - {{ channel ? channel.name : '' }}
        </template>
        <div class="main" v-loading="loading" element-loading-text="进件中..." :element-loading-spinner="svg"
            element-loading-svg-view-box="-10, -10, 50, 50" element-loading-background="rgba(122, 122, 122, 0.8)">
            <div class="choose">
                <template v-if="channel && channel.code == 'gtxy'">
                    <el-form ref="formRef" :model="incomingForm" label-width="150px">
                        <el-form-item label="商户类型：" required style="width: 100%;">
                            <el-select v-model="merchant_type" :disabled="true" style="width: 100%;">
                                <el-option v-for="item in merchantTypes" :key="item.value" :value="item.value"
                                    :label="item.label" />
                            </el-select>
                        </el-form-item>
                        <el-form-item label="一级行业码：" required>
                            <el-select v-model="topMcc_type" @change="selectTopMcc" style="width: 100%;">
                                <el-option v-for="item in topMcc" :key="item.busMccId" :value="item.busMccId"
                                    style="width: 100%;" :label="item.busMccId + ' - ' + item.mccName" />
                            </el-select>
                        </el-form-item>
                        <el-form-item label="二级行业码：" prop="mcc_code" required>
                            <el-select v-model="incomingForm.mcc_code" :disabled="unShowMccCode" style="width: 100%;">
                                <el-option v-for="mccCode in mccCodeList" :key="mccCode.mccCode" style="width: 100%;"
                                    :value="mccCode.mccCode" :label="mccCode.mccCode + ' - ' + mccCode.mccName">
                                </el-option>
                            </el-select>
                        </el-form-item>
                        <el-form-item label="开户行代码：" prop="bank_code" required style="width: 100%;">
                            <el-select v-model="incomingForm.bank_code" filterable style="width: 100%;">
                                <el-option v-for="bankCode in bankCodeList" :key="bankCode.bankCode"
                                    :value="bankCode.bankCode" :label="bankCode.bankCode + ' - ' + bankCode.bankName">
                                </el-option>
                            </el-select>
                        </el-form-item>
                        <el-form-item label="商户简称：" prop="merchant_alias">
                            <el-input v-model="incomingForm.merchant_alias"
                                placeholder="普通商户至少填写5个字。不填写默认取商户信息的。小微商户不支持。" />
                        </el-form-item>
                    </el-form>
                </template>

                <template v-if="isFlowId && channel && channel.code == 'yinsheng'">
                    <el-form ref="formRef">
                        <el-form-item label="进件流水号：" prop="sys_flow_id">
                            <el-input v-model="incomingForm.sys_flow_id" disabled readonly></el-input>
                        </el-form-item>
                    </el-form>
                </template>
                <tmeplate v-if="channel && channel.code == 'post'">
                    <el-form ref="formRef">
                        <el-form-item label="微信特约商户号：" prop="wx_mer_no">
                            <el-input v-model="incomingForm.wx_mer_no" />
                        </el-form-item>
                    </el-form>
                </tmeplate>
            </div>
            <div class="methods">
                <payment_method ref="paymentRef" v-if="channel" :methods="channel.payment_methods"
                    :isShowExtraCost="false" :channelCode="channel.code">
                </payment_method>
            </div>
        </div>
        <template #footer>
            <el-button @click="cancel">
                取消
            </el-button>
            <el-button type="primary" @click="incoming">
                进件
            </el-button>
        </template>
    </el-drawer>
</template>

<script setup>
import { ref, defineExpose, defineEmits, getCurrentInstance } from 'vue'
import payment_method from '../../components/payment_method.vue';
import { ElMessage, ElMessageBox } from 'element-plus';
const emit = defineEmits(["success"])
const { proxy } = getCurrentInstance()
const isShow = ref(false)
const merchant = ref(null)
const channel = ref(null)
const formRef = ref(null)
const loading = ref(false)
const isFlowId = ref(false)
// const isDisabledApplyAgreement = ref(false)
// const applyAgreementLoading = ref(false)
// const agreementOrderNo = ref(null)
let incomingForm = ref({
    mcc_code: ''
})
// const mechanism = ref([{
//     name: '953648', value: '953648'
// },
// {
//     name: '956116', value: '956116'
// }
// ])


const unShowMccCode = ref(true)

const merchant_type = ref()
const merchantTypes = [
    { value: 1, label: '个体工商户' },
    { value: 2, label: '企业' },
    { value: 3, label: '政府组织及事业单位' },
    { value: 4, label: '小微商户' },
    { value: 5, label: '其他机构组织' }
]

const topMcc = ref([])
const topMcc_type = ref('')
const selectMerchantType = (e) => {
    proxy.$api.merchant.income.getGtxyTopMcc({ merchant_type: e }).then(r => {
        if (r.status == 200) {
            topMcc.value = r.data.data

            getIncomingInfo(merchant.value.id)

        }
    })
}

const selectTopMcc = (e) => {
    proxy.$api.merchant.income.getGtxySecondMcc({ first_mcc_id: e }).then(r => {
        if (r.status == 200) {
            mccCodeList.value = r.data.data
            unShowMccCode.value = false

            // if (incomingInfo.value.mcc_code != null) {
            //     incomingForm.value.mcc_code = incomingInfo.value.mcc_code
            // }
        }
    })

}


const mccCodeList = ref([])
const bankCodeList = ref([])

const svg = `
        <path class="path" d="
          M 30 15
          L 28 17
          M 25.61 25.61
          A 15 15, 0, 0, 1, 15 30
          A 15 15, 0, 1, 1, 27.99 7.5
          L 15 15
        " style="stroke-width: 4px; fill: rgba(0, 0, 0, 0)"/>
      `
const paymentRef = ref(null)
const incoming = () => {
    loading.value = true
    incomingSubmit()
}

const incomingSubmit = () => {
    const methods = paymentRef.value.sendMethodForm()
    if (channel.value.code == 'yinsheng') { // 银盛通道
        let params = {
            channel_id: channel.value.id,
            merchant_id: merchant.value.id,
            methods: methods
        }
        switch (incomeType.value) {
            case 1:
                if (isFlowId.value) {
                    params['sys_flow_id'] = incomingForm.value.sys_flow_id
                    proxy.$api.merchant.income.confirmMaterial(params).then(r => {
                        if (r.status == 200) {
                            window.open(r.data.signUrl)
                            // ElMessage({ message: '进件成功! 签约地址："' + r.data.signUrl + '" 如果是浏览器打开地址失败，进件需要手动复制签约地址到手机端进行合同签约！', type: 'success', duration: 0, showClose: true })
                            ElMessageBox.confirm(
                                '进件成功! 签约地址："' + r.data.signUrl + '"， 如果是浏览器打开地址失败，需要手动复制签约地址到手机端进行合同签约！',
                                '提示',
                                {
                                    confirmButtonText: '复制URL地址',
                                    type: "warning"
                                }
                            ).then(() => {
                                copyWord(r.data.signUrl)
                                isShow.value = false
                            })
                        } else {
                            ElMessage({ message: r.error.message, type: 'error' })
                        }

                        loading.value = false
                    })
                } else {
                    proxy.$api.merchant.income.incoming(params).then(r => {
                        if (r.status == 200) {
                            incomingForm.value.sys_flow_id = r.data.sys_flow_id
                            isFlowId.value = true
                        } else {
                            ElMessage({ message: r.error.message, type: 'error' })
                        }
                        loading.value = false
                    })
                }
                break;
            case 2:
                proxy.$api.merchant.income.againIncoming(params).then(r => {
                    incomeResponse(r, '重新进件成功')
                })
                break;
            case 3:
                proxy.$api.merchant.income.changeMerchantRate(params).then(r => {
                    incomeResponse(r, '费率修改成功')
                })
                break;
        }

    } else { // 其他通道
        const params = {
            channel_id: channel.value.id,
            merchant_id: merchant.value.id,
            methods: methods,
            // proto_no: incomingForm.value.proto_no ? incomingForm.value.proto_no : '',
            // institution_no: incomingForm.value.institution_no ? incomingForm.value.institution_no : ''
        }
        if (channel.value.code == 'gtxy') {
            params['mcc_code'] = incomingForm.value.mcc_code
            params['bank_code'] = incomingForm.value.bank_code
            params['merchant_alias'] = incomingForm.value.merchant_alias
        }

        if (incomingForm.value.wx_mer_no) {
            params['wx_mer_no'] = incomingForm.value.wx_mer_no
        }
        switch (incomeType.value) {
            case 1:
                proxy.$api.merchant.income.incoming(params).then(r => {
                    incomeResponse(r, '进件申请成功')
                })
                break;
            case 2:
                proxy.$api.merchant.income.againIncoming(params).then(r => {
                    incomeResponse(r, '重新进件成功')
                })
                break;
            case 3:
                proxy.$api.merchant.income.changeMerchantRate(params).then(r => {
                    incomeResponse(r, '费率修改成功')
                })
                break;
        }

        // if (incomingForm.value.wx_mer_no) {
        //     params['wx_mer_no'] = incomingForm.value.wx_mer_no
        // }
        // proxy.$api.merchant.income.incoming(params).then(r => {
        //     if (r.status == 200) {
        //         ElMessage({ message: '进件申请成功', type: 'success' })

        //         if (channel.value.code != 'post') {
        //             emit("success", channel.value)
        //         }

        //         isShow.value = false
        //     } else {
        //         loading.value = false
        //         ElMessage({ message: r.error.message, type: 'error' })
        //     }
        // })
    }
}


const incomeResponse = (response, successMsg) => {
    if (response.status == 200) {
        ElMessage({ message: successMsg, type: 'success' })
        emit("success", channel.value)
        isShow.value = false
    } else {
        loading.value = false
        ElMessage({ message: response.error.message, type: 'error' })
    }
}

const cancel = () => {
    isShow.value = false
}


const incomeType = ref(null)
const open = (merchantInfo, channelInfo, type) => {
    isShow.value = true
    channel.value = channelInfo
    merchant.value = merchantInfo
    // mccCodeList.value = mccCodes[merchantInfo.business_type]
    incomeType.value = type
    proxy.$api.merchant.income.getGtxyBankinfo().then(r => {
        if (r.status == 200) {
            console.log(r.data);
            bankCodeList.value = r.data.data
        }
    })

    switch (merchantInfo.business_type) {
        case "0":
            merchant_type.value = 2;
            break;
        case "1":
            merchant_type.value = 1;
            break;
        case "2":
            merchant_type.value = 4;
            break;
        default:
            return;
    }

    selectMerchantType(merchant_type.value)

}

const copyWord = (word) => {
    let tag = document.createElement('textarea');
    tag.setAttribute('id', 'cp_hgz_input');
    tag.value = word;
    document.getElementsByTagName('body')[0].appendChild(tag);
    document.getElementById('cp_hgz_input').select();
    document.execCommand('copy');
    document.getElementById('cp_hgz_input').remove();

    ElMessage({ message: '复制成功', type: 'success' })
}


const incomingInfo = ref({})
const getIncomingInfo = (merchantId) => {
    proxy.$api.merchant.select(merchantId).then(r => {
        if (r.status == 200) {
            incomingInfo.value = r.data
            // if (incomingInfo.value.first_mcc_code != null) {
            //     selectTopMcc(incomingInfo.value.first_mcc_code)
            //     topMcc_type.value = incomingInfo.value.first_mcc_code
            //     unShowMccCode.value = false
            // }
        }
    })
}


defineExpose({ open })
</script>

<style lang="less">
.el-drawer__header {
    margin: 0;
    padding-bottom: 15px;
    border-bottom: 1px solid rgba(200, 200, 200, .5);
}

.el-drawer__footer {
    margin: 0;
    padding-top: 15px;
    border-top: 1px solid rgba(200, 200, 200, .5);
}
</style>
