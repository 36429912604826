<template>
    <div>
        <el-dialog v-model="isShow" width="600px" :draggable="true" :destroy-on-close="true">
            <template #title>
                <span>{{ activeName }}</span>
            </template>
            <div>
                <el-form :model="form" label-width="auto" style="max-width: 90%" ref="formRef" :rules="formRules">
                    <el-form-item label="商户号" prop="merchant_id">
                        <el-input v-model="form.merchant_id" :disabled="true" />
                    </el-form-item>

                    <el-form-item label="活动编号" prop="actId">
                        <el-input v-model="form.actId" :disabled="true" />
                    </el-form-item>

                    <el-form-item label="报名方式" prop="operType">
                        <el-radio-group v-model="form.operType">
                            <el-radio label="1">已开卡</el-radio>
                            <el-radio label="0">未开卡</el-radio>
                        </el-radio-group>
                    </el-form-item>

                    <div v-if="form.operType === '0'">
                        <el-form-item label="开卡人姓名" prop="accountName">
                            <el-input v-model="form.accountName" placeholder="请输入开卡人姓名" />
                        </el-form-item>

                        <el-form-item label="手机号" prop="phone">
                            <el-input v-model="form.phone" placeholder="请输入开卡人手机号" />
                        </el-form-item>

                        <el-form-item label="所在地" prop="addr">
                            <el-input v-model="form.addr" placeholder="请输入开卡人所在地" />
                        </el-form-item>

                        <el-form-item label="详细地址" prop="addrInfo">
                            <el-input v-model="form.addrInfo" placeholder="请输入开卡人详细地址" />
                        </el-form-item>

                        <el-form-item label="落地银行编号" prop="fallAgetId">
                            <el-input v-model="form.fallAgetId" placeholder="请输入落地银行编号" />
                        </el-form-item>
                    </div>

                    <div v-for="(item, index) in addList" :key="index">
                        <el-form-item :label="item.fieldName" v-if="item.inputType == '1'"
                            :prop="'addList[' + index + '].fieldValues'">
                            <el-input v-model="form.addList[index].fieldValues" :placeholder="'请输入' + item.fieldName" />
                        </el-form-item>
                        <el-form-item :label="item.fieldName" v-if="item.inputType == '2'"
                            :prop="'addList[' + index + '].fieldValues'">
                            <el-select v-model="form.addList[index].fieldValues" :placeholder="'请选择' + item.fieldName">
                                <el-option v-for="(option, optionIndex) in item.fieldValues.split('|')"
                                    :key="optionIndex" :label="option" :value="option" />
                            </el-select>
                        </el-form-item>
                        <el-form-item :label="item.fieldName" v-if="item.inputType == '3'"
                            :prop="'addList[' + index + '].fieldValues'">
                            <el-upload v-if="image_url == ''" class="upload-demo" drag style="width: 200px;"
                                action="https://admin-api.duimixinyifu.com/agent/merchants/upload" multiple
                                :show-file-list="false" :headers="uploadHeaders" :before-upload="beforeUpload"
                                :on-success="(response, file) => handleUploadSuccess(response, index)">
                                <el-icon class="el-icon--upload"><upload-filled /></el-icon>
                                <div class="el-upload__text">
                                    拖拽或 <em>点击上传</em>
                                </div>
                            </el-upload>

                            <img v-if="image_url !== ''" :src="image_url" alt=""
                                style="width: 200px;height: 200px;margin-right: 20px;">
                            <el-button v-if="image_url !== ''" type="danger" :icon="Delete"
                                @click="deleteImage">清除</el-button>
                        </el-form-item>
                    </div>

                    <div style="display: flex;justify-content: flex-end;">
                        <el-form-item>
                            <el-button @click="onCancel">取消</el-button>
                            <el-button type="primary" @click="onSubmit">确认</el-button>
                        </el-form-item>
                    </div>
                </el-form>
            </div>
        </el-dialog>
    </div>
</template>

<script setup>
import Tools from '@/tools/tools'
import token from '@/tools/tokenTools'
import { defineExpose, ref, getCurrentInstance, computed } from 'vue';
import { ElMessage } from 'element-plus'
import { UploadFilled, Delete } from '@element-plus/icons-vue'
const { proxy } = getCurrentInstance()

// 表单数据
const form = ref({
    merchant_id: '', //商户号
    actId: '', //银行补贴活动编号
    operType: '1', //报名方式  1-已开卡，0-未开卡
    accountName: '', //未开卡报名时必填
    phone: '', //未开卡报名时必填
    addr: '', //未开卡报名时必填
    addrInfo: '', //未开卡报名时必填
    fallAgetId: '', //未开卡报名时必填
    addList: [], //补充材料数组
})

const addList = ref([])

// 用于控制对话框的标题和是否显示
const activeName = ref('')
const isShow = ref(false)

const uploadHeaders = ref({});

// 打开对话框并设置数据
const open = (activeInfo, merchantId) => {
    isShow.value = true
    form.value.merchant_id = merchantId
    activeName.value = activeInfo.polName

    form.value.actId = activeInfo.polId

    addList.value = activeInfo.addList

    form.value.addList = activeInfo.addList.map(item => ({
        fieldId: item.fieldId,
        fieldName: item.fieldName,
    }));

    //设置图片上传请求头
    const accessToken = token.get();
    uploadHeaders.value.Authorization = Tools.ucFirst(accessToken.token_type) + accessToken.access_token;
}

// 限制图片大小为2m
const beforeUpload = (file) => {
    const isJpgOrPng = file.type === 'image/jpeg' || file.type === 'image/png';
    const isLt2M = file.size / 1024 / 1024 < 2;

    if (!isJpgOrPng) {
        ElMessage.error('上传图片只能是 JPG 或 PNG 格式!');
        return false;
    }
    if (!isLt2M) {
        ElMessage.error('上传图片大小不能超过 2MB!');
        return false;
    }
    return true; // 通过校验
};

const image_url = ref('')
const handleUploadSuccess = (response, index) => {
    // 上传成功时，处理参数
    if (response.success) {
        image_url.value = response.data.url
        uploadImg(index)
    }
}

// 清除图片Url
const deleteImage = () => {
    image_url.value = ''
}

// 动态表单验证规则
const formRules = computed(() => {
    const rules = {
        merchant_id: [{ required: true, message: '商户号不能为空', trigger: 'blur' }],
        actId: [{ required: true, message: '活动编号不能为空', trigger: 'blur' }],
        operType: [{ required: true, message: '请选择报名方式', trigger: 'change' }],
    }

    // 根据 operType 动态生成字段的验证规则
    if (form.value.operType === '0') {
        rules.accountName = [{ required: true, message: '账户名不能为空', trigger: 'blur' }];
        rules.phone = [{ required: true, message: '联系电话不能为空', trigger: 'blur' }];
        rules.addr = [{ required: true, message: '地址不能为空', trigger: 'blur' }];
        rules.addrInfo = [{ required: true, message: '详细地址不能为空', trigger: 'blur' }];
        rules.fallAgetId = [{ required: true, message: '补充材料不能为空', trigger: 'blur' }];
    }

    // 动态补充材料字段验证规则
    addList.value.forEach((item, index) => {
        const prop = `addList[${index}].fieldValues`
        if (item.inputType === '1') {
            rules[prop] = [{ required: true, message: `请输入${item.fieldName}`, trigger: 'blur' }];
        } else if (item.inputType === '2') {
            rules[prop] = [{ required: true, message: `请选择${item.fieldName}`, trigger: 'change' }];
        } else if (item.inputType === '3') {
            rules[prop] = [{ required: true, message: `${item.fieldName}不能为空`, trigger: 'change' }];
        }
    });

    return rules;
});

// 表单提交
const formRef = ref(null)
const onSubmit = () => {
    // 表单验证
    formRef.value.validate((valid) => {
        if (valid) {
            proxy.$api.merchant.activity.activitySubmit(form.value)
                .then((r) => {
                    if (r.code == '000000') {
                        ElMessage({ message: '申请成功', type: 'success' })
                    } else {
                        ElMessage({ message: r.data.msg, type: 'error', duration: 5000, showClose: true, center: true })
                    }
                })
                .catch((error) => {
                    console.error("Error fetching data: ", error)
                })
        } else {
            console.log('表单验证失败')
            return false
        }
    })
}

// 上传补充材料图片
const uploadImg = (index) => {
    proxy.$api.merchant.activity.uploadImg({
        image_url: image_url.value,
        merchant_id: 269
    }).then((r) => {
        form.value.addList[index].fieldValues = r.data.data.imgUrl
    }).catch((error) => {
        console.error("Error fetching data: ", error)
    })
}

// 关闭对话框
const onCancel = () => {
    isShow.value = false
}

defineExpose({ open })
</script>

<style lang="less" scoped>
/* 可以根据需要添加自定义样式 */
</style>
